import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PublicService} from "../../services/public.service";
import {FilterVideo, FilterVideoPublic, RequestVideo, Video} from "../../models/video";
import {Pagination} from "../../models/host";
import {environment} from "../../../environments/environment";
import {PageEvent} from "@angular/material/paginator";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  showProgresBar: boolean = true
  filterVideo: FilterVideoPublic = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    general: '',
    juzgadoCodList: 'ALL',
    useDateFilter: '0',
    lowerDate: '',
    upperDate: '',
    intervinienteNombre: '',
    procesoCod: '',
    etiqueta: '',
    district: '-1',
    area: '-1'
  }
  videos: Video[] = []
  pagination: Pagination = {
    CurrentPage: 0,
    TotalPages: 0,
    StartRow: 0,
    FinalRow: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }
  module = "videos"
  breadcrumbs = []
  pageSizes = environment.pageSizes;
  filterAdvanced: boolean = false
  requestVideo: RequestVideo

  optionsArea: string[] = []
  optionsDistricts : any[] = [];
  optionsJudged: string[] = []

  disabledDistricts: boolean = true
  disabledJudged: boolean = true
  keyFilter: string = 'Nombre'

  @ViewChild('areaInput') areaInput;
  @ViewChild('districtInput') districtInput;
  @ViewChild('judgedInput') judgedInput;


  constructor(public publicService: PublicService,
              public apiService: ApiService) { }

  ngOnInit(): void {
    this.getVideo()
    this._getFilterArea()
  }

  pageOnChange(event: PageEvent){
    this.showProgresBar = true;
    if(this.filterVideo.pageRecords != event.pageSize)
    {
      this.filterVideo.pageIndex = '1';
      this.filterVideo.pageRecords = event.pageSize
    }
    else
      this.filterVideo.pageIndex = String(event.pageIndex + 1);
    this.getVideo()
  }

  showFilterAdvanced(): void{
    this.filterAdvanced = !this.filterAdvanced;
  }

  getVideo(): void{
    this.showProgresBar = true
    this.publicService.getVideos(this.filterVideo).subscribe(
      requestVideos => {
        this.requestVideo = requestVideos;
        this.videos = this.requestVideo.Data;
        this.pagination = this.requestVideo.Pagination[0]
        this.showProgresBar = false
      },
      err => {
        console.log(err)
        this.showProgresBar = false
      }
    )
  }

  cleanFilter():void{
    this.filterVideo = {
      usePagination: true,
      orderField: 'FechaGrabacion',
      orderDirection: 'DESC',
      pageRecords: this.filterVideo.pageRecords,
      pageIndex: '1',
      general: '',
      juzgadoCodList: 'ALL',
      useDateFilter: '0',
      lowerDate: '',
      upperDate: '',
      intervinienteNombre: '',
      procesoCod: '',
      etiqueta: '',
      district: '-1',
      area: '-1'
    }
    this.optionsDistricts = []
    this.optionsJudged = []
    this.areaInput.clear()
    this.districtInput.clear()
    this.judgedInput.clear()
    this.disabledDistricts = true
    this.disabledJudged = true
    this.getVideo()
  }

  private _getFilterArea(): void{
    this.apiService.getArea().subscribe(
      request => {
        this.optionsArea = request;
      },
      error => {
        console.log(error);
      }
    )
  }

  onChangeAreaEvent(val: string){
    this.filterVideo.area = '-1'
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.disabledDistricts = true
    this.disabledJudged = true
    this.districtInput.clear()
    this.judgedInput.clear()
    this.areaInput.clear()
    this.optionsDistricts = []
    this.optionsJudged = []
  }
  onFocusedAreaEvent(e){
    this.filterVideo.area = '-1'
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.disabledDistricts = true
    this.disabledJudged = true
    this.districtInput.clear()
    this.judgedInput.clear()
    this.optionsDistricts = []
    this.optionsJudged = []
  }
  selectAreaEvent(item){
    this.filterVideo.area = item.AreaCod
    this.filterVideo.district = '-1'
    this.districtInput.clear()
    this.judgedInput.clear()
    this.filterVideo.juzgadoCodList = 'ALL'
    this.optionsJudged = []
    this.apiService.getDistricts(this.filterVideo.area).subscribe(
      request =>{
        this.optionsDistricts = request;
        if(this.optionsArea.length > 0)
        {
          this.disabledDistricts = false
          this.disabledJudged = true
        }
        else
        {
          this.disabledDistricts = true
          this.disabledJudged = true
        }
      },
      error => {

      }
    )
    this.getVideo()
  }


  selectDistrictsEvent(item){
    this.filterVideo.district = item.DistritoCod;
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.apiService.getJuzged(this.filterVideo.district, this.filterVideo.area).subscribe(
      request =>{
        this.optionsJudged = request;
        if(this.optionsJudged.length > 0)
        {
          this.disabledJudged = false
        }
      },
      error => {

      }
    )
    this.getVideo()
  }

  onChangeDistrictsEvent(val: string){
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.disabledJudged = true
  }
  onFocusedDistrictsEvent(e){
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.disabledJudged = true
    this.getVideo()
  }

  selectJudgedEvent(item){
    this.filterVideo.juzgadoCodList = item.JuzgadoCod
    this.getVideo()
  }

  onFocusedJudgedEvent(e){
    this.filterVideo.juzgadoCodList = 'ALL'
    this.getVideo()
  }

}
