import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FilterVideoPublic, VideoDetail} from "../../models/video";

@Component({
  selector: 'app-svideopublic',
  templateUrl: './svideopublic.component.html',
  styleUrls: ['./svideopublic.component.scss']
})
export class SvideopublicComponent implements OnInit {
  filterVideo: FilterVideoPublic = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    general: '',
    juzgadoCodList: 'ALL',
    useDateFilter: '0',
    lowerDate: '',
    upperDate: '',
    intervinienteNombre: '',
    procesoCod: '',
    etiqueta: '',
    district: '-1',
    area: '-1'
  }
  urlApi = environment.urlApiVideos.url;
  constructor() { }

  ngOnInit(): void {
  }
  @Input('videos') videos: VideoDetail []

  getHour(hora: string): string{
    let splitHour = hora[0]+hora[1]
    let splitMin = hora[2]+hora[3]
    let time = ' AM'
    if (parseInt(splitHour) >= 12) {
      time = ' PM'
      if (parseInt(splitHour) > 12) {
        splitHour = String(parseInt(splitHour) - 12)
      }else{
        splitHour = String(parseInt(splitHour))
      }

    }

    return splitHour + ':' + splitMin + time
  }
  getProcess(process: string){
    const lsform = localStorage.getItem('formSearch')
    if(lsform !== null) {
      this.filterVideo = JSON.parse(lsform)
    }
    this.filterVideo.procesoCod = process
    localStorage.setItem('formSearch', JSON.stringify(this.filterVideo))
    window.location.reload()
  }

}
