import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FilterVideo, VideoDetail } from '../../models/video'
import { environment } from '../../../environments/environment'
import { Router } from "@angular/router";

@Component({
  selector: 'app-svideo',
  templateUrl: './svideo.component.html',
  styleUrls: ['./svideo.component.scss']
})
export class SvideoComponent implements OnInit {
  filterVideo: FilterVideo = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    general: '',
    juzgadoCodList: 'ALL',
    useDateFilter: '1',
    lowerDate: '',
    upperDate: '',
    intervinienteNombre: '',
    procesoCod: '',
    etiqueta: '',
    district: '-1',
    area: '-1'
  }
  urlApi = environment.urlApiVideos.url;
  @Input("ProcedureValue") ProcedureValue
  constructor(private router: Router) { }
  ngOnInit(): void {
  }
  @Input('videos') videos: VideoDetail[]

  getHour(hora: string): string {
    let splitHour = hora[0] + hora[1]
    let splitMin = hora[2] + hora[3]
    let time = ' AM'
    if (parseInt(splitHour) >= 12) {
      time = ' PM'
      if (parseInt(splitHour) > 12) {
        splitHour = String(parseInt(splitHour) - 12)
      }else{
        splitHour = String(parseInt(splitHour))
      }

    }

    return splitHour + ':' + splitMin + time
  }
  getProcess(process: string) {
    const lsform = localStorage.getItem('formSearch')
    if (lsform !== null) {
      this.filterVideo = JSON.parse(lsform)
    }
    this.filterVideo.procesoCod = process
    localStorage.setItem('formSearch', JSON.stringify(this.filterVideo))
    window.location.reload()
  }


}
