import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { LogsService } from "../../services/logs.service";
import { Router } from "@angular/router";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,

} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  labels: any;
};


@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  token = ''
  showProbresBar: boolean = true
  breadcrumbs = []
  ProcedureValue = {
    "id": 0,
    "name": "",
    "type": "secretary",
    "judged": "",
    "judged_id": "",
    "office": "",
    "entity_name": "",
    "specialty_name": "",
    "email": "adm04sinc@cendoj.ramajudicial.gov.co",
    "name_complete": ""
  }


  @ViewChild("chart") chart: ChartComponent;
  public chartOptionsAccumulated: Partial<ChartOptions>;
  public chartOptionsDaily: Partial<ChartOptions>;
  public chartOptionsMonthly: Partial<ChartOptions>;
  public chartOptionsConsumption: Partial<ChartOptions>;


  constructor(private router: Router,
    public apiService: ApiService,
    public logService: LogsService) { }

  licencia: any;
  tiempo: any;
  validacion: any;
  fecha_dia: string[] = [];
  horas_dia: number[] = [];
  fecha_acumulado: string[] = [];
  horas_acumulado: number[] = [];
  fecha_mensual: string[] = [];
  horas_mensual: number[] = [];
  horas_consumidas: number[] = [];
  lista: any = [
    {
      name: "Todos los años",
      value: "1",
    }, {
      name: "Últimos tres meses",
      value: "3",
    }, {
      name: "Últimos seis meses",
      value: "6",
    }, {
      name: "Últimos doces meses",
      value: "12",
    }
  ];
  filtro_statistics = "1";

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if (this.token != null) {
      this.filtro_statistics = "1";
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      this.breadcrumbs = [
        { name: 'Inicio', url: '/' + this.token + '/' + localStorage.getItem('user_id') },
        { name: 'Estadísticas', url: '/statistics/' },
      ]
      this.apiService.getStatisticValidationUser(localStorage.getItem('user_id')).subscribe({
        next: data => {
          this.validacion = data['ValidateDashboardsUser'][0].exists;
        },
        complete: ()=> {if(this.validacion == 0){this.exit()}}
      })
      this.statistics(this.filtro_statistics);
    }
    else
      this.exit()
  }

  capturar() {
    this.statistics(this.filtro_statistics);



  }

  private statistics(value: string): void {
    this.fecha_acumulado = [];
    this.horas_acumulado = [];
    this.fecha_dia = [];
    this.horas_dia = [];
    this.fecha_mensual = [];
    this.horas_mensual = [];
    this.horas_consumidas = [];
    this.apiService.getStatisticDateCompany(localStorage.getItem('user_id')).subscribe(data => {
      for (let i = 0; i < 1; i++) {
        this.licencia = data['ValidityDateByCompanyOrder'][i];
      }
    })
    this.apiService.getStatisticTimeConsumed(localStorage.getItem('user_id')).subscribe(data => {
      for (let i = 0; i < 1; i++) {
        this.tiempo = data['ReportTimeConsumedCards'][i];
      }
    })
    this.apiService.getStatisticDailyConsumption(localStorage.getItem('user_id')).subscribe(data => {
      for (let i = 0; i < data['DailyConsumptionDetail'].length; i++) {
        this.fecha_dia.push(data['DailyConsumptionDetail'][i].Day);
        this.horas_dia.push(Math.floor(data['DailyConsumptionDetail'][i].HoursConsumed * 100) / 100);
      }

      this.chartOptionsDaily = {
        series: [
          {
            name: "Horas Acumuladas",
            data: this.horas_dia,
            color: "#007166",
          }
        ],
        chart: {
          height: 450,
          type: "bar"
        },
        title: {
          text: "Detalle Por Día"
        },
        xaxis: {
          categories: this.fecha_dia
        }
      };
    })
    this.apiService.getStatisticAccumulatedConsumption(localStorage.getItem('user_id'), value).subscribe(data => {
      for (let i = 0; i < data['AccumulatedMonthlyConsumptionByCompany'].length; i++) {
        this.fecha_acumulado.push(data['AccumulatedMonthlyConsumptionByCompany'][i].YearMonth);
        this.horas_acumulado.push(Math.floor(data['AccumulatedMonthlyConsumptionByCompany'][i].CumulativeHours * 100) / 100);
      }

      this.chartOptionsAccumulated = {
        series: [
          {
            name: "Horas Acumuladas",
            data: this.horas_acumulado,
            color: "#007166",
          }
        ],
        chart: {
          height: 450,
          type: "line"
        },
        title: {
          text: "Gráfica Acumulado"
        },
        xaxis: {
          categories: this.fecha_acumulado
        }
      };
    })

    this.apiService.getStatisticMonthlyConsumption(localStorage.getItem('user_id'), value).subscribe(data => {

      for (let i = 0; i < data['MonthlyConsumptionByCompany'].length; i++) {
        this.fecha_mensual.push(data['MonthlyConsumptionByCompany'][i].YearMonth);
        this.horas_mensual.push(Math.floor(data['MonthlyConsumptionByCompany'][i].HoursConsumed * 100) / 100);
      }

      this.chartOptionsMonthly = {
        series: [
          {
            name: "Horas",
            data: this.horas_mensual,
            color: "#007166",
          }
        ],
        chart: {
          height: 450,
          type: "bar"
        },
        title: {
          text: "Consumo Mensual (Horas)"
        },
        xaxis: {
          categories: this.fecha_mensual
        }
      };
    })

    // this.apiService.getStatisticConsumption(localStorage.getItem('user_id'), value).subscribe(data => {

    //   for (let i = 0; i < data['ConsumptionByCompany'].length; i++) {
    //     this.horas_consumidas.push(Math.floor(data['ConsumptionByCompany'][i].HoursConsumed * 100) / 100);
    //   }


    //   this.chartOptionsConsumption = {
    //     series: [
    //       {
    //         data: this.horas_consumidas
    //       }
    //     ],
    //     chart: {
    //       height: 450,
    //       type: "pie"
    //     },
    //     title: {
    //       text: "Consumo Mensual"
    //     },
    //     labels: [
    //       "Monday",
    //       "Tuesday",
    //       "Wednesday",
    //       "Thursday",
    //       "Friday",
    //       "Saturday"
    //     ],
    //   };
    // })

  }







  exit(): void {
    this.router.navigate(['/'])
  }
}

