import {Component, Input, OnInit} from '@angular/core';
import { ApiService } from "../../services/api.service";

export interface Breadcrumb {
  name: string,
  url: string
}
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  inputs: []
})
export class ToolbarComponent implements OnInit{
  constructor(public apiService: ApiService) { }
  judged: string = ''
  @Input('token') token: string
  @Input('breadcrumbs') breadcrumbs : Breadcrumb []
  @Input('ProcedureValue') ProcedureValue = {
    "id": 0,
    "name": "",
    "type": "secretary",
    "judged": "",
    "judged_id": "",
    "office": "",
    "entity_name": "",
    "specialty_name": "",
    "email": "",
    "name_complete": ""
  }
  validacion: any;

  ngOnInit(): void {
    this.judged = localStorage.getItem('user_id')
    // this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
    this.apiService.getStatisticValidationUser(localStorage.getItem('user_id')).subscribe({
      next: data => {
        this.validacion = data['ValidateDashboardsUser'][0].exists;
      },
      complete: () => {}
    })
  }


}
