<mat-toolbar style="background-color: rgb(0,113,102);" role="heading">
  <span class="toolbar-spacer"></span>
  <img src="../../../assets/CTLogPlusblanco.png" width="100"/>
</mat-toolbar>
<div class="main-content">
  <div *ngIf="video.GrabacionCod < 0">
    <p style="text-align: center; color: red;">
      {{requestSave.ProcedureMessage | uppercase}}
    </p>
  </div>
    <div class="row">
      <div class="col m3">
        Reproducciones: {{infoShare.NroReproducciones}} de {{infoShare.ReproduccionesMax}}
      </div>
      <div class="col m6">
        Fecha limite de reproducción: {{infoShare.FechaLimite | date:"yyyy/MM/dd"}}
      </div>
      <div class="col m3" *ngIf="infoShare.DescargarVideo">
        <a mat-button mat-fab color="warn"
           matTooltip="Descargar"
           href="{{urlApi}}/api/filevideo/descargarArchivo?videoName={{video.NombreArchivo}}"
           download target="_blank">
          <mat-icon>get_app</mat-icon>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col xl8 l8 m12 s12">
        <video
          class="video-js vjs-big-play-centered pgg-video vjs-16-9"
          controls
          playsinline
          preload="none" #idVideo [poster]="poster">
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
      <div class="col xl4 l4 m12 s12">
        <div class="row">
          <div class="col m6 l12 s12">
            <strong>Identificación:</strong> <br> {{video.ProcesoCod}}
          </div>
          <div class="col m6 l6 s12">
            <strong>Fecha:</strong> <br> {{video.FechaGrabacion | date:"yyyy/MM/dd"}}
          </div>
          <div class="col m6 s12">
            <strong>Tipo de actuación:</strong> <br> Audiencia
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <strong>Despacho:</strong> <br> {{video.JuzgadoNombre | uppercase}}
          </div>
          <div class="col s12">
            <strong>Juez:</strong> <br> {{video.NombreJuez | uppercase}}
          </div>
          <div class="col m6 s12">
            <strong>Jurisdicción:</strong> <br> {{video.JurisdiccionNombre | uppercase}}
          </div>
          <div class="col m6 s12">
            <strong>Area:</strong> <br> {{video.AreaNombre | uppercase}}
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="video">
      <div class="col xl8 offset-xl2 l8 offset-l2 m12 s12">
        <mat-tab-group>
          <mat-tab label="Tags" >
            <div class="row" *ngIf="video.TiempoEtiquetas != null && video.TiempoEtiquetas.length > 0">
              Listado de etiquetas de tiempo de reproducción:
              <br>
              <div class="col s12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip *ngFor="let tag of video.TiempoEtiquetas"
                            matTooltip="{{tag.TiempoMostrar.slice(0,8)}}" (click)="updateCurrentTime(tag.Segundos)">
                    {{tag.Descripcion | lowercase}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <p *ngIf="video.TiempoEtiquetas == null || video.TiempoEtiquetas.length == 0">
              No hay tags de video
            </p>
          </mat-tab>
          <mat-tab label="Documentos asociados">
            <table mat-table [dataSource]="video.Adjuntos" class="centered" *ngIf="video.Adjuntos">
              <ng-container matColumnDef="Descripcion">
                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let adjunto"> {{adjunto.NombreArchivo}} </td>
              </ng-container>
              <ng-container matColumnDef="FechaRegistro">
                <th mat-header-cell *matHeaderCellDef> Fecha de registro </th>
                <td mat-cell *matCellDef="let adjunto"> {{ adjunto.FechaRegistro | date:"yyyy/MM/dd HH:mm" }} </td>
              </ng-container>
              <ng-container matColumnDef="gestion">
                <th mat-header-cell *matHeaderCellDef> Descargar adjunto </th>
                <td mat-cell *matCellDef="let adjunto">
                  <a mat-button href="{{url_download_file}}{{adjunto.AdjuntoCod}}" target="_blank" *ngIf="infoShare.DescargarAdjunto">
                    <mat-icon>get_app</mat-icon>
                  </a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsAdjunts"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsAdjunts;"></tr>
            </table>
            <p *ngIf="!video.Adjuntos">
              No hay archivos adjuntos
            </p>

          </mat-tab>
        </mat-tab-group>
      </div>
    </div>



</div>

<app-footer></app-footer>

