import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LogsComponent } from './components/logs/logs.component'
import { VideoComponent } from './components/video/video.component'
import { TagsComponent } from './components/tags/tags.component'
import { TagVideoComponent } from './components/tag-video/tag-video.component'
import { AttachmentsComponent } from './components/attachments/attachments.component'
import { DetailvideoComponent } from './components/detailvideo/detailvideo.component'
import { ShareComponent } from './components/share/share.component'
import { LogoutComponent } from './components/logout/logout.component';
import { PublicComponent } from './components/public/public.component';
import { PublicdetailComponent } from './components/publicdetail/publicdetail.component';
import { TransliteratedComponent } from './components/transliterated/transliterated.component';
// hosts
import { ListhostsComponent } from './components/hosts/listhosts/listhosts.component';
import { UpdatehostsComponent } from './components/hosts/updatehosts/updatehosts.component';
import { CreatehostsComponent } from './components/hosts/createhosts/createhosts.component';

//my share
import { ListshareComponent } from './components/listshare/listshare.component';
import { DetailshareComponent } from './components/detailshare/detailshare.component';
import { SprocessComponent } from './components/sprocess/sprocess.component';
import { SProcessDetailComponent } from './components/sprocessdetail/sprocessDetail.component';

//statistics
import { StatisticsComponent } from './components/statistics/statistics.component';

const routes: Routes = [


  {path: 'attachments/:id', component: AttachmentsComponent},
  {path: 'detail/:id', component: DetailvideoComponent},
  {path: 'hosts', component: ListhostsComponent},
  {path: 'hosts/create', component: CreatehostsComponent},
  {path: 'hosts/edit/:id', component: UpdatehostsComponent},
  {path: 'list_share', component: ListshareComponent},
  {path: 'list_share/detail/:id', component: DetailshareComponent},
  {path: 'public', component: PublicComponent},
  {path: 'public/detail/:id', component: PublicdetailComponent},
  {path: 'registers', component: LogsComponent},
  {path: 'share/:token', component: ShareComponent},
  {path: 'tags/:id', component: TagsComponent},
  {path: 'tagsvideo/:id', component: TagVideoComponent},
  {path: 'transliterator', component: TransliteratedComponent},
  {path: 'shareProcess/:id', component: SprocessComponent},
  {path: 'shareProcess/:idProcess/:id', component: SProcessDetailComponent},

  {path: 'statistics', component: StatisticsComponent},
  // {path: 'parametrics', component: ParametricComponent},
  {path: ':token/:user_id', component: VideoComponent},
  {path: '**', component: LogoutComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
