import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from "rxjs";
import {Log, FilterLog, RequestLog, RequestIp} from "../models/logs";
import { RequestRU } from "../models/video";

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  urlApi = environment.urlApiVideos.url;
  headers: HttpHeaders
  basic: string

  constructor(private _http: HttpClient) {
    this.createBtoaLogin()
  }
  createBtoaLogin(): void {
    this.basic = btoa(environment.urlApiVideos.user+':'+environment.urlApiVideos.password)
  }

  getLogs(filter: FilterLog): Observable<RequestLog> {
    let dateFilterVideo = '1'
    let upperDate: any = ''
    let lowerDate: any = ''
    if(filter.lowerDate !== '' && filter.upperDate !== '' &&
      filter.lowerDate !== null && filter.upperDate !== null){
      lowerDate = filter.lowerDate.toISOString().substring(0, 10);
      upperDate = filter.upperDate.toISOString().substring(0, 10);
    }else{
      try{
        lowerDate = filter.lowerDate._d.toISOString().substring(0, 10);
        upperDate = filter.upperDate._d.toISOString().substring(0, 10);
      } catch (e) {
        let date_now = new Date()
        upperDate = date_now.toISOString().substring(0, 10);
        lowerDate = date_now.toISOString().substring(0, 10);
        dateFilterVideo = '0'
      }
    }
    let patch = '/api/Portal/LogEventos/TableView?usePagination=true'+
      '&pageIndex='+filter.pageIndex+'&pageRecords='+filter.pageRecords+
      '&orderField=FechaRegistro&orderDirection=DESC&usuario='+filter.usuario+
      '&modulo='+filter.modulo+'&descripcion='+filter.descripcion+'&useDateFilter='+
      dateFilterVideo+"&lowerDate="+lowerDate+"&upperDate="+upperDate;
    return this._http.get<RequestLog>(
      this.urlApi + patch,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      })
  }
  getLogsCsv(filter: FilterLog): Observable<RequestLog> {
    let dateFilterVideo = '1'
    let upperDate: any = ''
    let lowerDate: any = ''
    if(filter.lowerDate !== '' && filter.upperDate !== '' &&
      filter.lowerDate !== null && filter.upperDate !== null){
      lowerDate = filter.lowerDate.toISOString().substring(0, 10);
      upperDate = filter.upperDate.toISOString().substring(0, 10);
    }else{
      try{
        lowerDate = filter.lowerDate._d.toISOString().substring(0, 10);
        upperDate = filter.upperDate._d.toISOString().substring(0, 10);
      } catch (e) {
        let date_now = new Date()
        upperDate = date_now.toISOString().substring(0, 10);
        lowerDate = date_now.toISOString().substring(0, 10);
        dateFilterVideo = '0'
      }
    }
    let patch = '/api/Portal/LogEventos/TableView?usePagination=false'+
      '&pageIndex='+filter.pageIndex+'&pageRecords='+filter.pageRecords+
      '&orderField=FechaRegistro&orderDirection=DESC&usuario='+filter.usuario+
      '&modulo='+filter.modulo+'&descripcion='+filter.descripcion+'&useDateFilter='+
      dateFilterVideo+"&lowerDate="+lowerDate+"&upperDate="+upperDate;
    return this._http.get<RequestLog>(
      this.urlApi + patch,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      })
  }


  createLog(module: string, description: string): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/LogEventos/Insert',
      {
        Usuario: localStorage.getItem("user"),
        TokenSesion: localStorage.getItem("token"),
        Modulo: module,
        Descripcion: description,
        IpCliente: localStorage.getItem("ip"),
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      })
  }

  public getIPAddress(): void{
    let ip_get = this._http.get<RequestIp>(environment.urlApiIP);
    ip_get.subscribe(
      request =>{
        let ip = request.ip
        localStorage.setItem("ip", ip)
      },
      error => {

      }
    )
  }

}
