<mat-toolbar style="background-color: rgb(0,113,102);" role="heading">
  <!-- <span>{{ title }}</span> -->
  <nav>
    <div class="nav-wrapper yellow darken-1" >
      <div class="col s12">
        <a *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb" routerLink="{{breadcrumb.url}}">
          {{breadcrumb.name}}
        </a>
      </div>
    </div>
  </nav>

  <span class="toolbar-spacer"></span>

  <img src="../../../assets/CTLogPlusblanco.png" width="100"/>
  <!--
  <span style="color: rgb(248, 249, 250);">{{ title }}</span>
  -->

  <button mat-icon-button class="example-icon favorite-icon"
          [matMenuTriggerFor]="menu"
          style="color: rgb(248, 249, 250);"
          aria-label="Example icon-button with heart icon" *ngIf="token">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu>
    <a mat-menu-item routerLink="/{{token}}/{{judged}}">Inicio</a>
    <a mat-menu-item routerLink="/list_share">Compartidos</a>
    <a mat-menu-item routerLink="/transliterator">Búsqueda por transliteración</a>
    <a mat-menu-item routerLink="/registers" *ngIf="ProcedureValue.type == 'scheduler'">Logs</a>
    <a mat-menu-item routerLink="/statistics" *ngIf="validacion==1">Estadísticas</a>
<!--    <a mat-menu-item routerLink="/hosts" *ngIf="ProcedureValue.type == 'scheduler'">Equipos</a>-->
  </mat-menu>
</mat-toolbar>

