import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {VideoDetail} from "../../models/video";

@Component({
  selector: 'app-svideoshare',
  templateUrl: './svideoshare.component.html',
  styleUrls: ['./svideoshare.component.scss']
})
export class SvideoshareComponent implements OnInit {
  urlApi = environment.urlApiVideos.url;
  constructor() { }

  ngOnInit(): void {
  }
  @Input('videos') videos: VideoDetail []

  getHour(hora: string): string{
    let splitHour = hora[0]+hora[1]
    let splitMin = hora[2]+hora[3]
    let time = ' AM'
    if (parseInt(splitHour) >= 12) {
      time = ' PM'
      if (parseInt(splitHour) > 12) {
        splitHour = String(parseInt(splitHour) - 12)
      }else{
        splitHour = String(parseInt(splitHour))
      }

    }

    return splitHour + ':' + splitMin + time
  }

}
