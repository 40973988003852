<div mat-dialog-content>
  <mat-toolbar style="background-color: rgb(0,113,102); color: white;">
    {{message.title}}
  </mat-toolbar>
  <br>
  {{message.subtitle}}
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onClickNo()" >Cancelar</button>
  <button mat-button [mat-dialog-close]="true"
          mat-raised-button
          color="primary">Aceptar</button>

</div>
