import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Host, FilterHost, RequestHost } from "../../../models/host"
import {environment} from "../../../../environments/environment";
import {ApiService} from "../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { RequestRU } from "../../../models/video"
import {LogsService} from "../../../services/logs.service";

@Component({
  selector: 'app-updatehosts',
  templateUrl: './updatehosts.component.html',
  styleUrls: ['./updatehosts.component.scss']
})
export class UpdatehostsComponent implements OnInit {
  password = 'password'
  module = "equipos"
  breadcrumbs = []
  token: string
  id: string
  host: Host
  form: FormGroup;
  ProcedureValue = {}

  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              public logService: LogsService,
              private _snackBar: MatSnackBar,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    let host = localStorage.getItem('host')
    // validar datos de usuario
    if(this.token != null)
    {
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      this.activeRoute.params.subscribe(params => {
        this.id = params.id;
      })
      if(host!=null){
        this.host = <Host>JSON.parse(host)
      }
      this.form = this.formBuilder.group({
        Clave: [this.host.Clave, {validators: [Validators.required]}],
        IP: [this.host.IP, {validators: [Validators.required]}],
        Nombre: [this.host.Nombre, {validators: [Validators.required]}],
        Puerto: [this.host.Puerto, {validators: [Validators.required]}],
        Ruta: [this.host.Ruta, {validators: [Validators.required]}],
        Usuario: [this.host.Usuario, {validators: [Validators.required]}]
      })

      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Equipos', url: '/hosts/'},
        {name: 'Editar Equipo', url: '/hosts/edit/'+this.id+'/'},
      ]
    }
    else
      this.exit()
  }
  exit(): void{
    this.router.navigate(['/'])
  }

  back(): void{
    this.router.navigate(['/hosts/'])
  }

  saveHost(): void{
    this.apiService.UpdateHost(this.host).subscribe(
      request =>{
        let requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          localStorage.setItem('msghost', "Modificación exitosa del equipo")
          var str_host = 'IP: '+this.host.IP+', Puerto: '+this.host.Puerto+', Usuario: '+this.host.Usuario+', Ruta: '+
            this.host.Ruta+', Nombre: '+this.host.Nombre;
          this.logService.createLog(this.module,
            "Edición de equipo: "+ str_host).subscribe(
            request =>console.log("ok"),
            error => console.error(error)
          )
          this.back();
        }
      },
      error => {
        console.log(error)
        console.log('Error')
        this._snackBar.open("Error al registrar el Equipo", null,
          {
            duration: 6000,
            panelClass: "red-snackbar"
          })
      }
    )
  }

  clearForm(): void{
    this.host = {
      Clave: '',
      IP: '',
      Nombre: '',
      Puerto: '21',
      Ruta: '',
      Usuario: '',
      ServidorVideoCod: this.host.ServidorVideoCod,
      Activo: this.host.Activo
    }
  }

  changeType(): void{
    if(this.password == 'password'){
      this.password = 'text'
    }
    else{
      this.password = 'password'
    }
  }
}
