<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue">
</app-toolbar>

<div class="row">
  <div class="col s12 m12 l12 xl5 d-flex justify-content-center" *ngIf="licencia" style="text-align: center;">
    <h1>Tiempo del Contrato</h1>
    <div class="col s12 m12 l5 xl5 card-blanco">
      <h2>Inicio</h2>
      <hr>
      <h2>{{licencia.StartDate}}</h2>
    </div>
    <div class="col s12 m12 l5 xl5 card-color">
      <h2>Vencimiento</h2>
      <hr>
      <h2>{{licencia.EndDate}}</h2>
    </div>

  </div>
  <div class="col s12 m12 l12 xl5 d-flex justify-content-center" *ngIf="tiempo" style="text-align: center;">
    <h1>Detalle de Horas</h1>
    <div class="col s12 m12 l3 xl3 card-gris">
      <h2>Consumidas</h2>
      <hr>
      <h2>{{tiempo.hoursconsumed}}</h2>
    </div>
    <div class="col s12 m12 l3 xl3 card-blanco">
      <h2>Disponibles</h2>
      <hr>
      <h2>{{tiempo.hoursavailable}}</h2>
    </div>
    <div class="col s12 m12 l3 xl3 card-color">
      <h2>Obtenidas</h2>
      <hr>
      <h2>{{tiempo.hoursearned}}</h2>
    </div>
  </div>
  <div class="col s12 m12 l12 xl2 " style="text-align: center;">
    <h1>Generar</h1>
    <div class="col s12 m12 l11 xl10 card-seleccion">
      <h2>Rango de fechas</h2>
      <hr>
      <h2>
        <select name="miselect" [(ngModel)]="filtro_statistics" class='form-control' (change)="capturar()"
          style="text-align: center;">
          <option [value]="item.value" *ngFor="let item of lista">{{item.name}}</option>
        </select>
      </h2>
    </div>
    <!-- {{filtro_statistics}} -->
  </div>
</div>
<div class="row">
  <div class="col s12 m12 l12 xl12 " *ngIf="chartOptionsMonthly">
    <div class="card-grafica">
      <apx-chart [series]="chartOptionsMonthly.series" [chart]="chartOptionsMonthly.chart"
        [xaxis]="chartOptionsMonthly.xaxis" [title]="chartOptionsMonthly.title"></apx-chart>
    </div>

  </div>
</div>
<div class="row">

  <div class="col s12 m12 l6 xl6 " *ngIf="chartOptionsDaily">
    <div class="card-grafica">
      <apx-chart [series]="chartOptionsDaily.series" [chart]="chartOptionsDaily.chart" [xaxis]="chartOptionsDaily.xaxis"
        [title]="chartOptionsDaily.title"></apx-chart>
    </div>

  </div>
  <div class="col s12 m12 l6 xl6 " *ngIf="chartOptionsAccumulated">
    <div class="card-grafica">
      <apx-chart [series]="chartOptionsAccumulated.series" [chart]="chartOptionsAccumulated.chart"
        [xaxis]="chartOptionsAccumulated.xaxis" [title]="chartOptionsAccumulated.title"></apx-chart>
    </div>
  </div>

</div>

<app-footer></app-footer>