import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import videojs from 'video.js';
import { MatDialog } from '@angular/material/dialog';

import {VideoShare, VideoDetail, RequestRU, VideoShareInterno, ShareInterno, FilterVideo, ReservateVideo} from "../../models/video";
import { ApiService } from "../../services/api.service";
import { environment } from '../../../environments/environment'
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {LogsService} from "../../services/logs.service";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component'
import {Location} from "@angular/common";
import { AlertsService } from 'src/app/services/alert.services';


@Component({
  selector: 'app-detailvideo',
  templateUrl: './detailvideo.component.html',
  styleUrls: ['./detailvideo.component.scss']
})
export class DetailvideoComponent implements OnInit {
  filterVideo: FilterVideo = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    general: '',
    juzgadoCodList: 'ALL',
    useDateFilter: '1',
    lowerDate: '',
    upperDate: '',
    intervinienteNombre: '',
    procesoCod: '',
    etiqueta: '',
    district: '-1',
    area: '-1'
  }
  urlApi = environment.urlApiVideos.url;
  url_download_file = environment.urlApiVideos.url + '/api/Portal/Adjuntos/DownloadFile?adjuntoCod='
  process_share_video = false
  process_share_video_2 = false
  process_share_video_list = false
  module = "detalle video"
  breadcrumbs = []
  formShare: FormGroup;
  formShareInterno: FormGroup;
  token = ''
  displayedColumns = ["Usuario", "CorreoCompartido", "Días", "NroReproducciones",
    "DescargarVideo", "DescargarAdjunto", "FechaRegistro"]
  displayedColumnsAdjunts = ["Descripcion", "FechaRegistro", "gestion"]
  displayedColumnsShareInterno = ["Usuario", "UsuarioFinal", "FechaRegistro", "gestion"]
  title = environment.title;
  videoId:string = ''
  video: VideoDetail = {
    CiudadCodAudiencia: "",
    EntidadCodAudiencia: "",
    EspecialidadCodAudiencia: "",
    Estado: "",
    Etiquetas: [],
    FechaGrabacion: "",
    FechaRegistro: "",
    GrabacionCod: 0,
    HoraGrabacion: "",
    JuzgadoCodAudiencia: "",
    JuzgadoNombre: "",
    NombreArchivo: "",
    NombreSala: "",
    NumeroGrabacion: "",
    OrigenGrabacion: "",
    ProcesoCod: "111",
    RutaDecodificado: "",
    RutaOriginal: "",
    TipoAudiencia: "",
    VideosCompartidos: [],
    Adjuntos: [],
    VideoCompartidoUsuarios: [],
    DistritoCod: '',
    DistritoNombre: '',
    AreaCod: '',
    AreaNombre: '',
    JurisdiccionCod: '',
    JurisdiccionNombre: '',
    NombreJuez: '',
    TiempoEtiquetas: [],
    CaracterReservado: true,
    Duracion: '',
    EtiquetasLista: ''
  }
  videoShare: VideoShare = {
    CorreoCompartido: '',
    Dias: '',
    GrabacionCod: '',
    ReproduccionesMax: '',
    Usuario: '',
    descargarAdjunto: false,
    descargarVideo: false,
    CompartirTodoslosVideos: false
  }
  videoShareInterno: VideoShareInterno = {
    Usuario: "",
    GrabacionCod: "",
    UsuarioFinal: ""
  }
reservateVideo: ReservateVideo = {
  ProcesoCod: "",
  TipoAudiencia: "",
  CantidadGrabacionesReservadas: 0
}


  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;
  player: videojs.Player;
  options = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4'
      }
    ]
  };
  poster:string = ''
  ProcedureValue: any = {}

  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              private router: Router,
              public logService: LogsService,
              private formBuilder: FormBuilder,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private alert: AlertsService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if(this.token != null)
    {
      this.activeRoute.params.subscribe(params => {
        this.videoId = params.id;
        this.GetVideoDetail()
        let formT = localStorage.getItem('formTransliterator')
        if (formT == null){
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId}
          ]
        }else{
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Búsqueda por transliteración', url: '/transliterator'},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId}
          ]
        }

      })
      this.formShare = this.formBuilder.group({
        CorreoCompartido: ['', {validators: [Validators.required,
            Validators.email]}],
        Dias: ['', {validators: [Validators.required]}],
        ReproduccionesMax: ['', {validators: [Validators.required]}]
      })
      this.formShareInterno = this.formBuilder.group({
        UsuarioFinal: ['', {validators: [Validators.required, Validators.email]}],
      })
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))

      this.videoShareInterno.Usuario = this.ProcedureValue.email;
      this.videoShare.Usuario = this.ProcedureValue.email;
      this.videoShare.GrabacionCod = this.videoId;
      this.videoShareInterno.GrabacionCod = this.videoId;
    }
    else{
      this.exit()
    }
  }


  exit(): void{
    this.router.navigate(['/'])
  }

  GetVideoDetail(): void{
    this.apiService.GetInfoVideo(this.videoId).subscribe(
      request=>{

        this.video = <VideoDetail> request;

        let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
        this.options.sources[0].src = src_video;
        const type = this.video.NombreArchivo.split('.')[1]
        if(type === 'mp3'){
          this.options.sources[0].type = 'audio/mp3'
          this.poster = '../../../assets/poster.jpg'
        }else{
          this.options.sources[0].type = 'video/mp4'
          this.poster = ''
        }
        this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
          console.log('onPlayerReady');
        })
        this.logService.createLog(this.module,
          "visualización del video " +this.video.NombreArchivo).subscribe(
          request =>console.log("ok"),
          error => console.error(error)
        )
      },
      error => {

      }
    )
  }

  showMsg(msg: string):void{
    this._snackBar.open(msg, null,
      {
        duration: 8000,
        panelClass: 'green-snackbar',
        horizontalPosition: 'end',
        verticalPosition: 'top',
      })
  }

  showMsgTranscription(msg: string):void{
    this._snackBar.open(msg, null,
      {
        duration: 8000,
        panelClass: 'green-snackbar',
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
      })
  }

  ShareVideo(): void{

    let etiqueta = ""

    if(this.video.Etiquetas.length>0){
      etiqueta = this.video.Etiquetas[0].Nombre
    }

    this.process_share_video = true;
    this.apiService.ShareVideo(this.videoShare,etiqueta).subscribe(
      request =>{
        let requestSave = <RequestRU> request;
        let msg: string
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          var str_video = 'Correo: '+this.videoShare.CorreoCompartido+', Días compartido: '+this.videoShare.Dias + ', ' +
            'Reproduciones: '+this.videoShare.ReproduccionesMax + ', Descargar video: '+this.videoShare.descargarVideo+', ' +
            'Descargar adjuntos: '+this.videoShare.descargarAdjunto + ', Compartir videos del proceso: ' + this.videoShare.CompartirTodoslosVideos
          this.logService.createLog(this.module,
            "Compartir video :" +str_video).subscribe(
            request =>console.log("ok"),
            error => console.error(error)
          )
          this.clearFormShare()
          msg = 'El video se ha compartido con éxito'
          this.GetVideoDetail()
        }
        else
          msg = 'Error al intentar compartir el video, favor intertar nuevamente'
        this.showMsg(msg);
        this.process_share_video = false

      }, error => {
      }
    )
  }

  confirmReservate(){
    if(this.videoShare.CompartirTodoslosVideos){
      this.alert.loadingShow();
      this.apiService.getReservateVideo(this.video.ProcesoCod).subscribe(request => {
        if(request.CantidadGrabacionesReservadas != 0){
          this.reservateVideo = request;
          this.alert.loadingClose();
          this.showModalReservateConfirm();
        }else{
          this.alert.loadingClose();
          this.ShareVideo();
        }
      });
    }else{
      this.ShareVideo();
    }
  }

  showModalReservateConfirm():void{
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {'title': 'Alerta:',
        'subtitle': 'En este expediente hay ' + this.reservateVideo.CantidadGrabacionesReservadas + ' audiencia(s) reservada(s), ¿está seguro de compartirlas?'}
    });
    dialogRef.afterClosed().subscribe(
      res=>{
        if (res){
          console.log('Prueba');
          this.ShareVideo();
        }else{
          this.videoShare.CompartirTodoslosVideos = false;
        }
      });
  }

  clearFormShare(): void{
    this.videoShare.CorreoCompartido = ''
    this.videoShare.Dias = ''
    this.videoShare.Usuario = ''
    this.videoShare.ReproduccionesMax = ''
    this.videoShare.descargarAdjunto = false
    this.videoShare.descargarVideo = false
  }

  clearFormShareInterno(): void{
    this.videoShareInterno.UsuarioFinal = ''
  }

  ShareVideoInterno(): void{
    this.process_share_video_2 = true
    this.apiService.ShareVideoInterno(this.videoShareInterno).subscribe(
      request =>{
        let requestSave = <RequestRU> request;
        let msg: string
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          var str_video = 'Quien compartío: '+this.videoShareInterno.Usuario+', A quien se le compartío: '+
            this.videoShareInterno.UsuarioFinal+'Video: '+this.video.NombreArchivo
          this.logService.createLog(this.module,
            "Compartir video usuario interno:" +str_video).subscribe(
            request =>console.log("ok"),
            error => console.error(error)
          )
          this.clearFormShareInterno()
          this.GetVideoDetail()
          msg = 'El video se ha compartido con éxito al usuario'
        }
        else
          msg = requestSave.ProcedureMessage;
        this.showMsg(msg);
        this.process_share_video_2 = false

      }, error => {
      }
    )
  }

  DesactivateShare(share: ShareInterno): void{
    this.process_share_video_list = true;
    this.apiService.UpdateStatusShareInterno(share.VideoCompartidoUsuarioID).subscribe(
      request=>{
        this.logService.createLog(this.module,
          'Eliminación video compartido: '+ share.VideoCompartidoUsuarioID)
        let requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          this.showMsg("El video se eliminó correctamente de los videos compartidos");
          this.GetVideoDetail();
        }
        this.process_share_video_list = false
      },
      error => {

      }
    )
  }

  updateCurrentTime(time: number): void{
    this.player.currentTime(time)
    this.player.play()
  }

  showModalDeactivateConfirm():void{
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {'title': '¿Deseas desactivar el video?',
        'subtitle': 'Recuerda que esta acción no se puede deshacer y el video dejará de ser visible.'}
    });
    dialogRef.afterClosed().subscribe(
      res=>{
        if (res)
          this.DeactivateVideo()
      }
    )
  }

  DeactivateVideo():void{
    this.apiService.DeactivateVideo(this.videoId).subscribe(
      request =>{
        let requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          this.showMsg("El video se desactivo correctamente");
          this.GetVideoDetail();
        }
        this.logService.createLog(this.module,
          'Video desactivado: '+ this.videoId)

      },error => {

      }
    )
  }

  UpdateReservedVideo():void{
    let active = this.video.CaracterReservado
    this.apiService.UpdateReservedVideo(this.videoId).subscribe(
      request =>{
        if(active){
          this.logService.createLog(this.module,'Video público: '+ this.videoId)
          this.showMsg('El video ahora es PÚBLICO')
        }
        else{
          this.showMsg('El video ahora es PRIVADO')
          this.logService.createLog(this.module,'Video privado: '+ this.videoId)
        }
        this.GetVideoDetail()
      }, error => {

      }
    )
  }

  RequestTranscription(): void{
    // 05001310300120180057300
    this.apiService.RequestTranscription(this.videoId).subscribe(
      request => {
        const requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          this.showMsgTranscription("La grabación será transcrita y se enviará un correo cuando el proceso haya terminado");
          let name = this.video.NombreArchivo
          if(this.video.Duracion){

          }
          this.logService.createLog('transliteración',
            "Solicitud de transcripción " +name).subscribe(
            request =>console.log("ok"),
            error => console.error(error)
          )
          this.GetVideoDetail();
        }
        else{
          this.showMsgTranscription(requestSave.ExecutionMessage);
        }
      },
      error => {

      }
    )
  }

  getHour(hora: string): string {
    let splitHour = hora[0]+hora[1]
    let splitMin = hora[2]+hora[3]
    let time = ' AM'
    if (parseInt(splitHour) >= 12) {
      time = ' PM'
      if (parseInt(splitHour) > 12) {
        splitHour = String(parseInt(splitHour) - 12)
      }else{
        splitHour = String(parseInt(splitHour))
      }

    }

    return splitHour + ':' + splitMin + time
  }

  segToStr(time: string): string {
    let time_seg = parseInt(time)
    let seg = 0
    seg = time_seg % 60
    time_seg =  time_seg - seg
    time_seg = time_seg / 60
    let min = 0
    if(time_seg > 0){
      min = time_seg % 60
      time_seg =  time_seg - min
      time_seg = time_seg / 60
    }
    let hour = 0
    if(time_seg > 0){
      hour = time_seg % 60
      time_seg =  time_seg - hour
      time_seg = time_seg / 60
    }
    return hour + ':' + min + ':' + seg
  }

  getProcess(process: string){
    const lsform = localStorage.getItem('formSearch')
    if(lsform !== null) {
      this.filterVideo = JSON.parse(lsform)
    }
    this.filterVideo.procesoCod = process
    localStorage.setItem('formSearch', JSON.stringify(this.filterVideo))
    this.router.navigate(['/'+this.token+'/'+localStorage.getItem('user_id')])
  }
}
