<div class="row">
  <div class="col s6 m4 l3 xl3" *ngFor="let video of videos" style="height: 460px">
    <mat-card>
      <p style="font-size: 14px;padding-bottom: 5px;">
        Identificación:
        <a (click)="getProcess(video.ProcesoCod)"
           matTooltip="Buscar por proceso"
           mat-button color="primary"
           style="padding-left: 0px;padding-right: 0px;">
          {{video.ProcesoCod}}
        </a>
      </p>

      <a routerLink='/detail/{{ video.GrabacionCod }}' matTooltip="Reproducir video">
        <img mat-card-image
             src="{{urlApi}}/api/Portal/Grabaciones/Thumbnail?VideoName={{video.NombreArchivo}}"
             alt="Photo of CTLogPanel">
      </a>

      <mat-card-content>
        <div class="row" style="margin-bottom: 0;">
          <div class="col s6" style="padding: 0;">
            <p>
              Fecha: <strong>{{video.FechaGrabacion | date:"yyyy/MM/dd"}}</strong>
            </p>
          </div>
          <div class="col s6" style="padding: 0;">
            <p>
              Hora: <strong>{{getHour(video.HoraGrabacion)}}</strong>
            </p>
          </div>
        </div>
        <p style="margin-top: 0;">
          <strong>
            {{video.JuzgadoNombre}}
          </strong>
        </p>
        <p style="margin-top: 0;">
          <strong>
            {{video.EtiquetasLista!='NULL'?video.EtiquetasLista:''}}
          </strong>
        </p>
      </mat-card-content>
      <mat-card-actions>
        <a mat-button color="primary"
           matTooltip="Descargar"
           href="{{urlApi}}/api/filevideo/descargarArchivo?videoName={{video.NombreArchivo}}"
           download target="_blank" style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>get_app</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar palabras claves"
           routerLink="/tags/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>label</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar adjuntos"
           routerLink="/attachments/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>attach_file</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar etiquetas de tiempo de reproducción"
           routerLink="/tagsvideo/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>video_label</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
    <!--<app-card-video [video]="video" [ProcedureValue]="ProcedureValue"></app-card-video>-->
  </div>

</div>
