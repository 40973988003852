import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import { environment } from '../../environments/environment'
import { User, RequestToken } from '../models/user'
import {RequestRU} from "../models/video";

@Injectable({
  providedIn: 'root',
})
export class OdooService {
  private dataService = environment.urlApiVideos;
  basic: string
  constructor(private _http: HttpClient) {
    this.createBtoaLogin()
  }

  createBtoaLogin(): void {
    this.basic = btoa(environment.urlApiVideos.user+':'+environment.urlApiVideos.password)
  }

  getUser(token: string, UserId: string): Observable<RequestRU> {
    return this._http.get<RequestRU>(
      this.dataService.url + '/api/Portal/Todoo/UserValidate?Token='+token+'&UserId='+UserId,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
      )
  }


}
