import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {FilterVideo, RequestVideo, VideoDetail} from "../models/video";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  // /api/Portal/Grabaciones/PublicTableView?usePagination=true&pageIndex=0&pageRecords=20&orderField=GrabacionCod&orderDirection=ASC&juzgadoCodList=ALL&useDateFilter=0&lowerDate=1900-01-01&upperDate=1900-01-01&intervinienteNombre=&procesoCod=&etiquetas=
  urlApi = environment.urlApiVideos.url;
  headers: HttpHeaders
  basic: string
  constructor(private _http: HttpClient) {
    this.createBtoaLogin()
  }
  createBtoaLogin(): void {
    this.basic = btoa(environment.urlApiVideos.user+':'+environment.urlApiVideos.password)
  }

  getVideos(filterVideo: FilterVideo): Observable<RequestVideo> {
    let dateFilterVideo = '1'
    let upperDate = ''
    let lowerDate = ''
    if(filterVideo.lowerDate !== '' && filterVideo.upperDate !== ''){
      lowerDate = filterVideo.lowerDate.toISOString().substring(0, 10);
      upperDate = filterVideo.upperDate.toISOString().substring(0, 10);
    }
    else if(filterVideo.lowerDate !== '' && filterVideo.upperDate === ''){
      lowerDate = filterVideo.lowerDate.toISOString().substring(0, 10);
      let date_now = new Date()
      upperDate = date_now.toISOString().substring(0, 10);
    }
    else{
      try{
        lowerDate = filterVideo.lowerDate._d.toISOString().substring(0, 10);
        upperDate = filterVideo.upperDate._d.toISOString().substring(0, 10);
      } catch (e) {
        let date_now = new Date()
        upperDate = date_now.toISOString().substring(0, 10);
        lowerDate = date_now.toISOString().substring(0, 10);
        dateFilterVideo = '0'
      }
    }
    filterVideo.useDateFilter = dateFilterVideo

    return this._http.get<RequestVideo>(
      this.urlApi + '/api/Portal/Grabaciones/PublicTableView?usePagination=true&pageIndex=' +
      filterVideo.pageIndex+'&pageRecords='+filterVideo.pageRecords+'&orderField='+
      filterVideo.orderField+'&orderDirection='+filterVideo.orderDirection+
      "&juzgadoCodList="+filterVideo.juzgadoCodList+"&useDateFilter="+dateFilterVideo+"&lowerDate="+lowerDate
      +"&upperDate="+upperDate+"&intervinienteNombre="+
      '&procesoCod='+filterVideo.procesoCod+'&etiquetas='+filterVideo.etiqueta+'&distritoCod='+filterVideo.district
      +'&areaCod='+filterVideo.area+'&nombreJuez='+filterVideo.intervinienteNombre,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  GetInfoVideo(id: string): Observable<VideoDetail>{
    return this._http.get<VideoDetail>(
      this.urlApi + '/api/Portal/Grabaciones/Read?GrabacionCod='+id,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
}
