import { Injectable } from "@angular/core";
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AlertsService{

  constructor(){}

  options={
    icon: 'question',
    message: '',
    timer: '',
    from: 'top',
    align: 'right',
    type: 'success',
    title: '',
    text: '',
    buttonStyling: false,
    showCancelButton: false,
    confirmButton: 'btn',
    confirmButtonColor: '',
    cancelButtonColor: '',
    confirmButtonText: '',
    cancelButtonText: '',
    closeOnClickOutside: false,
  };

  icon : any = 'question';

  setOptions(options: any){

  }

  loadingShow(): void{
    swal.fire({
      //icon: 'info',
      width: 250,
      title: 'Cargando...',
      allowOutsideClick: false,
      didOpen: () =>{
        swal.showLoading();
      }
    });
  }

  loadingClose():void{
    swal.close();
  }
}