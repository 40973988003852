// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlApiVideos: {
    //url: 'https://apigestionaudiencias1.ramajudicial.gov.co',
    //url: 'http://localhost:53017',
    //url: 'http://192.168.0.19/TECGO.PortalGrabaciones.WebApi',
    url:'https://apigestionaudiencias1.ramajudicial.gov.co',
    // url: 'http://172.27.64.25/Api',
    //url: 'http://ctlogbotmsteams.southcentralus.cloudapp.azure.com/TECGO.PortalGrabaciones.WebApi/',
    user: 'USR_A3FAA51025C84819A4ABF550DB4A3ECF',
    password: 'D1B2290324EA479A8AB74B92EE1A82FC'
  },
  title: 'Gestión de videos',
  pageSizes: [10, 30, 50],
  urlApiIP: "http://api.ipify.org/?format=json"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
