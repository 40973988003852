<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue"></app-toolbar>
<div class="main-content">
  <div class="row">
    <div class="col xl8 l8 m12 s12">
      <video
        class="video-js vjs-big-play-centered pgg-video vjs-16-9"
        controls
        playsinline
        preload="auto" #idVideo>

        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
    <div class="col xl4 l4 m12 s12">
      <section>
        <button mat-raised-button color="warn"
                (click)="showModalDeactivateConfirm()" *ngIf="ProcedureValue.type == 'scheduler'">Desactivar video
        </button>
        <button mat-raised-button color="warn"
                (click)="UpdateReservedVideo()"
                matTooltip="Permitir que el video sea público"
        *ngIf="video.CaracterReservado && ProcedureValue.type === 'secretary'">Publicar</button>
        <button mat-raised-button color="primary"
                (click)="UpdateReservedVideo()"
                matTooltip="Colocar el video privado"
                *ngIf="!video.CaracterReservado && ProcedureValue.type === 'secretary'">Reservar</button>

      </section>
      <section>
        <a mat-button color="primary"
           matTooltip="Descargar"
           href="{{urlApi}}/api/filevideo/descargarArchivo?videoName={{video.NombreArchivo}}"
           download target="_blank" style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>get_app</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar palabras claves"
           routerLink="/tags/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>label</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar adjuntos"
           routerLink="/attachments/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>attach_file</mat-icon>
        </a>
        <a mat-button color="primary"
           matTooltip="Gestionar etiquetas de tiempo de reproducción"
           routerLink="/tagsvideo/{{ video.GrabacionCod }}"
           style="padding-right: 8px;padding-left: 8px; margin: 0px;">
          <mat-icon>video_label</mat-icon>
        </a>
      </section>
      <mat-divider></mat-divider>
      <div class="row">
        <div class="col m12 l12 s12">
          <strong>Identificación:</strong> <br>
            <a (click)="getProcess(video.ProcesoCod)"
               matTooltip="Buscar por proceso"
               mat-button color="primary"
               style="padding-left: 0px;padding-right: 0px;">
              {{video.ProcesoCod}}
            </a>
        </div>
        <div class="col m4 l4 s12">
          <strong>Fecha:</strong> <br> {{video.FechaGrabacion | date:"yyyy/MM/dd"}}
        </div>
        <div class="col m4 l3 s12">
          <strong>Hora:</strong> <br> {{getHour(video.HoraGrabacion)}}
        </div>
        <div class="col m4 l5 s12">
          <strong>Tipo de actuación:</strong> <br> Audiencia
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <strong>Despacho:</strong> <br> {{video.JuzgadoNombre | uppercase}}
        </div>
        <div class="col s12">
          <strong>Juez:</strong> <br> {{video.NombreJuez | uppercase}}
        </div>
        <div class="col m6 s12">
          <strong>Jurisdicción:</strong> <br> {{video.JurisdiccionNombre | uppercase}}
        </div>
        <div class="col m6 s12">
          <strong>Área:</strong> <br> {{video.AreaNombre | uppercase}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <mat-tab-group>
        <mat-tab label="Tags" >
          <div class="row" *ngIf="video.TiempoEtiquetas.length > 0">
            Listado de etiquetas de tiempo de reproducción:
            <br>
            <div class="col s12">
              <mat-chip-list aria-label="Fish selection">
                <mat-chip *ngFor="let tag of video.TiempoEtiquetas"
                          matTooltip="{{tag.TiempoMostrar.slice(0,8)}}" (click)="updateCurrentTime(tag.Segundos)">
                  {{tag.Descripcion | lowercase}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <p *ngIf="video.TiempoEtiquetas.length == 0">
            No hay tags de video
          </p>
        </mat-tab>
        <mat-tab label="Compartir">
          <form [formGroup]="formShare" (submit)="confirmReservate()">
            <div class="row" *ngIf="process_share_video">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div class="row">
              <div class="col s12 m6">
                <mat-form-field class="example-full-width" appearance="standard">
                  <mat-label>Correo</mat-label>
                  <input matInput
                         formControlName="CorreoCompartido"
                         required [(ngModel)]="videoShare.CorreoCompartido">
                </mat-form-field>
              </div>
              <div class="col s12 m6 l6">
                <mat-form-field class="example-full-width" appearance="standard">
                  <mat-label>Días máximos para compartir</mat-label>
                  <input matInput
                         formControlName="Dias"
                         [(ngModel)]="videoShare.Dias"
                         required type="number" min="1">
                </mat-form-field>
              </div>
              <div class="col s12 m6 l6">
                <mat-form-field class="example-full-width" appearance="standard">
                  <mat-label>Número de máximo de reproducciones</mat-label>
                  <input matInput
                         type="number"
                         formControlName="ReproduccionesMax"
                         [(ngModel)]="videoShare.ReproduccionesMax"
                         required min="1">
                </mat-form-field>
              </div>
              <div class="col s12 m6 14 x14 d">
                <div class="col">
                  <mat-checkbox [(ngModel)]="videoShare.descargarVideo"
                                [ngModelOptions]="{standalone: true}"
                                class="aling-checkbox-button">
                    Permitir descargar el video</mat-checkbox>
                </div>
                <div class="col">
                  <mat-checkbox [(ngModel)]="videoShare.descargarAdjunto"
                                [ngModelOptions]="{standalone: true}"
                                class="aling-checkbox-button">
                    Permitir descargar los adjuntos</mat-checkbox>
                </div>
                <div class="col">
                  <mat-checkbox [(ngModel)]="videoShare.CompartirTodoslosVideos"
                                [ngModelOptions]="{standalone: true}"
                                class="aling-checkbox-button">
                    Compartir todos los videos del proceso</mat-checkbox>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col m6 offset-m3">
                <button mat-button mat-flat-button
                        [disabled]="formShare.invalid"
                        class="aling-checkbox-button background-color-buttom">
                  <mat-icon>save</mat-icon> Compartir
                </button>
                <button mat-button mat-flat-button type="button"
                        class="aling-checkbox-button" (click)="clearFormShare()">
                  Borrar formulario <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="Compartir usuario interno">
          <form [formGroup]="formShareInterno" (submit)="ShareVideoInterno()">
            <div class="row" *ngIf="process_share_video_2">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="row">
              <div class="col s12">
                <mat-form-field class="example-full-width" appearance="standard">
                  <mat-label>Correo del usuario interno</mat-label>
                  <input matInput
                         formControlName="UsuarioFinal"
                         required [(ngModel)]="videoShareInterno.UsuarioFinal">
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col m6 offset-m3">
                  <button mat-button mat-flat-button
                          [disabled]="formShareInterno.invalid"
                          class="aling-checkbox-button background-color-buttom">
                    <mat-icon>save</mat-icon> Compartir
                  </button>
                  <button mat-button mat-flat-button type="button"
                          class="aling-checkbox-button" (click)="clearFormShareInterno()">
                    Borrar formulario <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>

            </div>
          </form>
        </mat-tab>
        <mat-tab label="Documentos adjuntos">
          <button mat-raised-button color="primary"
                  (click)="RequestTranscription()"
                  matTooltip="Solicitud de transcripción"
                  *ngIf="ProcedureValue.type === 'secretary'">Transcribir</button>
          <table mat-table [dataSource]="video.Adjuntos"
                 class="centered" *ngIf="video.Adjuntos.length > 0">
            <ng-container matColumnDef="Descripcion">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let adjunto"> {{adjunto.NombreArchivo}} </td>
            </ng-container>
            <ng-container matColumnDef="FechaRegistro">
              <th mat-header-cell *matHeaderCellDef> Fecha de registro </th>
              <td mat-cell *matCellDef="let adjunto"> {{ adjunto.FechaRegistro | date:"yyyy/MM/dd HH:mm" }} </td>
            </ng-container>
            <ng-container matColumnDef="gestion">
              <th mat-header-cell *matHeaderCellDef> Gestión </th>
              <td mat-cell *matCellDef="let adjunto">
                <a mat-button matTooltip="Descargar" mat-flat-button color="primary"
                   href="{{url_download_file}}{{adjunto.AdjuntoCod}}" download="" target="_blank">
                  <mat-icon>get_app</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsAdjunts"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAdjunts;"></tr>
          </table>
          <p *ngIf="video.Adjuntos.length == 0">
            No hay archivos adjuntos.
          </p>
        </mat-tab>
        <mat-tab label="Historial de compartido">
          <table mat-table [dataSource]="video.VideosCompartidos" class="centered" *ngIf="video.VideosCompartidos.length > 0">
            <ng-container matColumnDef="Usuario">
              <th mat-header-cell *matHeaderCellDef> Usuario que compartió </th>
              <td mat-cell *matCellDef="let share"> {{share.Usuario}} </td>
            </ng-container>

            <ng-container matColumnDef="CorreoCompartido">
              <th mat-header-cell *matHeaderCellDef> Correo </th>
              <td mat-cell *matCellDef="let share"> {{share.CorreoCompartido}} </td>
            </ng-container>

            <ng-container matColumnDef="Días">
              <th mat-header-cell *matHeaderCellDef> Días </th>
              <td mat-cell *matCellDef="let share"> {{ share.Dias }} </td>
            </ng-container>

            <ng-container matColumnDef="NroReproducciones">
              <th mat-header-cell *matHeaderCellDef> Reproducciones </th>
              <td mat-cell *matCellDef="let share"> {{share.ReproduccionesMax}} </td>
            </ng-container>

            <ng-container matColumnDef="DescargarVideo">
              <th mat-header-cell *matHeaderCellDef> Descargar video </th>
              <td mat-cell *matCellDef="let share">
                <mat-icon *ngIf="share.DescargarVideo">done</mat-icon>
                <mat-icon *ngIf="!share.DescargarVideo">close</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="DescargarAdjunto">
              <th mat-header-cell *matHeaderCellDef> Descargar adjuntos </th>
              <td mat-cell *matCellDef="let share">
                <mat-icon *ngIf="share.DescargarAdjunto">done</mat-icon>
                <mat-icon *ngIf="!share.DescargarAdjunto">close</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="FechaRegistro">
              <th mat-header-cell *matHeaderCellDef> Fecha de registro </th>
              <td mat-cell *matCellDef="let share"> {{ share.FechaRegistro | date:"yyyy/MM/dd HH:mm" }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <p *ngIf="video.VideosCompartidos.length == 0">
            <br>
            No hay historial de compartidos con externos.
          </p>
        </mat-tab>
        <mat-tab label="Historial de compartidos usuarios internos">
          <div class="row" *ngIf="process_share_video_list">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <table mat-table [dataSource]="video.VideoCompartidoUsuarios" class="centered" *ngIf="video.VideoCompartidoUsuarios.length > 0">
            <ng-container matColumnDef="Usuario">
              <th mat-header-cell *matHeaderCellDef> Usuario que compartió </th>
              <td mat-cell *matCellDef="let share"> {{share.Usuario}} </td>
            </ng-container>

            <ng-container matColumnDef="UsuarioFinal">
              <th mat-header-cell *matHeaderCellDef> Correo </th>
              <td mat-cell *matCellDef="let share"> {{share.UsuarioFinal}} </td>
            </ng-container>
            <ng-container matColumnDef="FechaRegistro">
              <th mat-header-cell *matHeaderCellDef> Fecha de registro </th>
              <td mat-cell *matCellDef="let share"> {{ share.FechaRegistro | date:"yyyy/MM/dd HH:mm" }} </td>
            </ng-container>
            <ng-container matColumnDef="gestion">
              <th mat-header-cell *matHeaderCellDef> Dejar de compartir </th>
              <td mat-cell *matCellDef="let share">
                <a mat-button matTooltip="Dejar de compartir" mat-flat-button
                   download="" target="_blank" *ngIf="share.Activo" (click)="DesactivateShare(share)">
                  <mat-icon>close</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsShareInterno"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsShareInterno;"></tr>
          </table>
          <p *ngIf="video.VideoCompartidoUsuarios.length == 0">
            No hay historial de compartidos con usuarios internos.
          </p>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<app-footer></app-footer>
