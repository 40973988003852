<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue">
</app-toolbar>
<div class="main-content">
  <form #parent >
    <div class="row">
      <div class="col s3">
        <mat-form-field class="example-full-width">
          <mat-label>Ip</mat-label>
          <input matInput [(ngModel)]="filterHosts.IP"
                 [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input matInput [(ngModel)]="filterHosts.Nombre"
                 [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <div class="col s1">
        <mat-checkbox [(ngModel)]="filterHosts.Activo"
                      [ngModelOptions]="{standalone: true}"
                      class="aling-checkbox-button">
          Activos</mat-checkbox>
      </div>
      <div class="col s3">
        <button mat-button mat-flat-button type="submit"
                class="aling-checkbox-button background-color-buttom"
        (click)="startGetHosts()">
          <mat-icon>search</mat-icon> Buscar
        </button>
        <button mat-button mat-flat-button type="button"
                class="aling-checkbox-button" (click)="clearFilter()">
          Borrar filtro <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="col s2">
        <button mat-button mat-flat-button type="button" color="accent"
                class="aling-checkbox-button background-color-buttom" (click)="newHost()">
          Nuevo equipo <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </form>

  <table mat-table [dataSource]="hosts" class="centered">
    <ng-container matColumnDef="IP">
      <th mat-header-cell *matHeaderCellDef> IP </th>
      <td mat-cell *matCellDef="let host"> {{host.IP}} </td>
    </ng-container>

    <ng-container matColumnDef="Usuario">
      <th mat-header-cell *matHeaderCellDef> Usuario </th>
      <td mat-cell *matCellDef="let host"> {{host.Usuario}} </td>
    </ng-container>

    <ng-container matColumnDef="Puerto">
      <th mat-header-cell *matHeaderCellDef> Puerto </th>
      <td mat-cell *matCellDef="let host"> {{host.Puerto}} </td>
    </ng-container>

    <ng-container matColumnDef="Clave">
      <th mat-header-cell *matHeaderCellDef> Clave </th>
      <td mat-cell *matCellDef="let host"> {{ host.Clave }} </td>
    </ng-container>

    <ng-container matColumnDef="Ruta">
      <th mat-header-cell *matHeaderCellDef> Ruta </th>
      <td mat-cell *matCellDef="let host"> {{host.Ruta}} </td>
    </ng-container>

    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let host"> {{host.Nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef> Gestión </th>
      <td mat-cell *matCellDef="let host">
        <a mat-button mat-flat-button matTooltip="Editar"
           (click)="edit(host)" >
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-button mat-flat-button matTooltip="Desactivar"
           (click)="updateStatus(host)" *ngIf="host.Activo">
          <mat-icon >close</mat-icon>
        </a>
        <a mat-button mat-flat-button matTooltip="Activar"
           (click)="updateStatus(host)" *ngIf="!host.Activo">
          <mat-icon>done</mat-icon>
        </a>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="pagination.TotalRows"
                 [pageSize]="filterHosts.pageRecords"
                 [pageSizeOptions]="pageSizes"
                 (page)="pageOnChange($event)" showFirstLastButtons>
  </mat-paginator>
</div>

