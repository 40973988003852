<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue">
</app-toolbar>
<div class="main-content">

  <form class="centered" #formCreate [formGroup]="form" (submit)="saveHost()">
    <div class="row">
      <div class="col m4 offset-m2">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Dirección Ip</mat-label>
          <input matInput formControlName="IP" [(ngModel)]="host.IP" required>
        </mat-form-field>
      </div>
      <div class="col m4">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label >Puerto FTP</mat-label>
          <input matInput formControlName="Puerto" [(ngModel)]="host.Puerto" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col m4 offset-m2">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Usuario de acceso</mat-label>
          <input matInput [(ngModel)]="host.Usuario"
                 formControlName="Usuario" required>
        </mat-form-field>
      </div>
      <div class="col m4">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label >Clave de acceso</mat-label>
          <input matInput [(ngModel)]="host.Clave"
                 formControlName="Clave" required [type]="password">
          <button mat-button *ngIf="host.Clave" matSuffix type="button"
                  mat-icon-button aria-label="Clear" (click)="changeType()">
            <mat-icon *ngIf="password=='password'">visibility</mat-icon>
            <mat-icon *ngIf="password=='text'">visibility_off</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col m4 offset-m2">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label>Ruta de la carpeta raíz</mat-label>
          <input matInput [(ngModel)]="host.Ruta"
                 formControlName="Ruta" required>
        </mat-form-field>
      </div>
      <div class="col m4">
        <mat-form-field class="example-full-width" appearance="standard">
          <mat-label >Nombre de equipo</mat-label>
          <input matInput [(ngModel)]="host.Nombre"
                 formControlName="Nombre" required>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col s4 offset-s3">
        <button mat-button mat-flat-button
                [disabled]="form.invalid"
                class="aling-checkbox-button background-color-buttom">
          <mat-icon>save</mat-icon> Guardar
        </button>
        <button mat-button mat-flat-button type="button"
                class="aling-checkbox-button" (click)="clearForm()">
          Borrar formulario <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="col s2">
        <button mat-button mat-flat-button type="button"
                color="accent"
                class="aling-checkbox-button background-color-buttom" (click)="back()">
          <mat-icon>undo</mat-icon>Regresar
        </button>
      </div>
    </div>
  </form>
</div>



