import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import videojs from 'video.js';
import {Validators} from "@angular/forms";
import {ShareInterno, VideoDetail} from "../../models/video";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {LogsService} from "../../services/logs.service";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-detailshare',
  templateUrl: './detailshare.component.html',
  styleUrls: ['./detailshare.component.scss']
})
export class DetailshareComponent implements OnInit {
  url_download_file = environment.urlApiVideos.url + '/api/Portal/Adjuntos/DownloadFile?adjuntoCod='
  module = "detalle video compartido"
  videoId:string = ''
  token = ''
  breadcrumbs = []
  displayedColumnsAdjunts = ["Descripcion", "FechaRegistro", "gestion"]
  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;
  player: videojs.Player;
  options = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4',
        poster: ''
      }
    ]
  };
  video: VideoDetail = {
    CiudadCodAudiencia: "",
    EntidadCodAudiencia: "",
    EspecialidadCodAudiencia: "",
    Estado: "",
    Etiquetas: [],
    FechaGrabacion: "",
    FechaRegistro: "",
    GrabacionCod: 0,
    HoraGrabacion: "",
    JuzgadoCodAudiencia: "",
    JuzgadoNombre: "",
    NombreArchivo: "",
    NombreSala: "",
    NumeroGrabacion: "",
    OrigenGrabacion: "",
    ProcesoCod: "111",
    RutaDecodificado: "",
    RutaOriginal: "",
    TipoAudiencia: "",
    VideosCompartidos: [],
    Adjuntos: [],
    VideoCompartidoUsuarios: [],
    DistritoCod: '',
    DistritoNombre: '',
    AreaCod: '',
    AreaNombre: '',
    JurisdiccionCod: '',
    JurisdiccionNombre: '',
    NombreJuez: '',
    TiempoEtiquetas: [],
    CaracterReservado: true,
    Duracion: '',
    EtiquetasLista: ''
  }
  poster:string = ''
  ProcedureValue = {}
  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              private router: Router,
              public logService: LogsService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    // validar datos de usuario
    if(this.token != null)
    {
      this.activeRoute.params.subscribe(params => {
        this.videoId = params.id;
        this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
        this.GetVideoDetailShare()
        this.breadcrumbs = [
          {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
          {name: 'Compartidos', url: '/list_share/'},
          {name: 'Detalle del video', url: '/detail/'+ this.videoId}
        ]
      })

    }
    else{
      this.exit()
    }
  }
  exit(): void{
    this.router.navigate(['/'])
  }

  GetVideoDetailShare(): void{
    this.apiService.GetInfoVideo(this.videoId).subscribe(
      request=>{
        this.video = <VideoDetail> request;
        let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
        this.options.sources[0].src = src_video;
        const type = this.video.NombreArchivo.split('.')[1]
        if(type === 'mp3'){
          this.options.sources[0].type = 'audio/mp3'
          this.poster = '../../../assets/poster.jpg'
        }else{
          this.options.sources[0].type = 'video/mp4'
          this.poster = ''
        }
        this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
        this.logService.createLog(this.module,
          "visualización del video " +this.video.NombreArchivo).subscribe(
          request =>console.log("ok"),
          error => console.error(error)
        )
      },
      error => {

      }
    )
  }
  updateCurrentTime(time: number): void{
    this.player.currentTime(time)
    this.player.play()
  }
  getHour(hora: string): string{
    let splitHour = hora[0]+hora[1]
    let splitMin = hora[2]+hora[3]
    let time = ' AM'
    if (parseInt(splitHour) >= 12) {
      time = ' PM'
      if (parseInt(splitHour) > 12) {
        splitHour = String(parseInt(splitHour) - 12)
      }else{
        splitHour = String(parseInt(splitHour))
      }

    }

    return splitHour + ':' + splitMin + time
  }

}
