import {Component, ElementRef, OnInit, ViewChild, HostListener} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {Label, RequestRU, VideoDetail} from "../../models/video";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {LogsService} from "../../services/logs.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import videojs from 'video.js';
import {HttpEventType} from "@angular/common/http";
import { environment } from "../../../environments/environment"


@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  urlApi = environment.urlApiVideos.url
  show_progres: boolean = false
  show_progres_delete: boolean = false
  progress = 0;
  progress_delete = 0;
  displayedColumnsAttachment = ["Nombre", "gestion"]
  attachmentForm = new FormGroup({
    file: new FormControl('',
      [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  token = ''
  breadcrumbs = []
  videoId:string = ''
  video: VideoDetail = {
    CiudadCodAudiencia: "",
    EntidadCodAudiencia: "",
    EspecialidadCodAudiencia: "",
    Estado: "",
    Etiquetas: [],
    FechaGrabacion: "",
    FechaRegistro: "",
    GrabacionCod: 0,
    HoraGrabacion: "",
    JuzgadoCodAudiencia: "",
    JuzgadoNombre: "",
    NombreArchivo: "",
    NombreSala: "",
    NumeroGrabacion: "",
    OrigenGrabacion: "",
    ProcesoCod: "",
    RutaDecodificado: "",
    RutaOriginal: "",
    TipoAudiencia: "",
    VideosCompartidos: [],
    Adjuntos: [],
    VideoCompartidoUsuarios: [],
    DistritoCod: '',
    DistritoNombre: '',
    AreaCod: '',
    AreaNombre: '',
    JurisdiccionCod: '',
    JurisdiccionNombre: '',
    NombreJuez: '',
    TiempoEtiquetas: [],
    CaracterReservado: true,
    Duracion: '',
    EtiquetasLista: ''
  }
  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;

  player: videojs.Player;
  module = "adjuntos"
  options = {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4',
        poster: ''
      }
    ]
  };
  poster:string = ''
  ProcedureValue = {}
  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              private router: Router,
              public logService: LogsService,
              private formBuilder: FormBuilder,
              private host: ElementRef<HTMLInputElement>,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if(this.token != null)
    {
      this.activeRoute.params.subscribe(params => {
        this.videoId = params.id;
        this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
        this.GetVideoDetail()
        let formT = localStorage.getItem('formTransliterator')
        if (formT == null){
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId},
            {name: 'Adjuntos del video', url: '/attachments/'+ this.videoId}
          ]
        }else{
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Búsqueda por transliteración', url: '/transliterator'},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId},
            {name: 'Adjuntos del video', url: '/attachments/'+ this.videoId}
          ]
        }

      })
    }
    else
      this.exit()
  }

  GetVideoDetail(): void{
    this.apiService.GetInfoVideo(this.videoId).subscribe(
      request=>{
        this.video = <VideoDetail> request;
        let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
        this.options.sources[0].src = src_video;
        const type = this.video.NombreArchivo.split('.')[1]
        if(type === 'mp3'){
          this.options.sources[0].type = 'audio/mp3'
          this.poster = '../../../assets/poster.jpg'
        }else{
          this.options.sources[0].type = 'video/mp4'
          this.poster = ''
        }
        this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
        this.logService.createLog(this.module,
          "visualización del video " +this.video.NombreArchivo).subscribe(
          request =>console.log("ok"),
          error => console.error(error)
        )
      },
      error => {

      }
    )
  }

  showMsg(msg: string):void{
    this._snackBar.open(msg, null,
      {
        duration: 8000,
        panelClass: 'green-snackbar',
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
      })
  }

  UploadAttachment():void {
    this.show_progres = true
    this.apiService.RegisterAttachment(this.attachmentForm, this.videoId).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }

        if (event.type === HttpEventType.Response) {
          let requestSave = <RequestRU> event.body
          if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
            this.showMsg('El archivo adjunto ha sido guardado con éxito')
            this.GetVideoDetail()
            this.logService.createLog(this.module,
              "Registro de adjunto " +this.video.NombreArchivo).subscribe(
              request =>console.log("ok"),
              error => console.error(error)
            )
          }
          else
            this.showMsg(requestSave.ProcedureMessage.toLowerCase())
          this.show_progres = false

        }
      }
    );
  }

  DeleteAttachment(attach_id: string): void{
    this.show_progres_delete = true
    this.apiService.RemoveAttachment(attach_id).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress_delete = Math.round((100 * event.loaded) / event.total);
        }
        if (event.type === HttpEventType.Response) {
          let requestSave = <RequestRU> event.body
          if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
            this.showMsg('El archivo adjunto se ha borrado con éxito')
            this.GetVideoDetail()
            this.logService.createLog(this.module,
              "Borrado de adjunto " +this.video.NombreArchivo).subscribe(
              request =>console.log("ok"),
              error => console.error(error)
            )
          }
          else
            this.showMsg(requestSave.ProcedureMessage.toLowerCase())
          this.show_progres_delete = false

        }

      }
    );
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.attachmentForm.patchValue({
        fileSource: file
      });
    }
  }

  exit(): void{
    this.router.navigate(['/'])
  }
}
