import { Component, OnInit, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { Pagination } from "src/app/models/host";
import { FilterVideoProcess, RequestProcess } from "src/app/models/video";
import { ApiService } from "src/app/services/api.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-sprocess',
  templateUrl: './sprocess.component.html',
  styleUrls: ['./sprocess.component.scss']
})
export class SprocessComponent implements OnInit {

  token = '';
  breadcrumbs = [];
  showProbresBar: boolean = true;
  videos: RequestProcess[] = [];
  pageSizes = environment.pageSizes;
  filterAdvanced: boolean = false;
  disableSearch: boolean = true;
  videoId:string = '';
  procesoCod!: string;

  filterVideoProcess: FilterVideoProcess = {
    usePagination: false,
    tokenDelProcesoCod: "",
    nombreJuez: "",
    pageIndex: "1",
    pageRecords: 10,
    useDateFilter: "",
    lowerDate: "",
    upperDate: "",
    etiquetas: ""
  }

  pagination: Pagination = {
    CurrentPage: 0,
    TotalPages: 0,
    StartRow: 0,
    FinalRow: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }

  constructor(
    private activeRoute: ActivatedRoute,
    public apiService: ApiService,
    ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.videoId = params.id;
      this.getProcessVideos(false);
      this.breadcrumbs = [
        {name: 'Inicio', url: '/public/'},
        {name: 'Detalle de proceso compartido', url: '/shareProcess/'+this.videoId+'/'},
      ]
    });
  }

  pageOnChange(event: PageEvent){
    this.showProbresBar = true;
    if(this.filterVideoProcess.pageRecords != event.pageSize){
      this.filterVideoProcess.pageIndex = '1';
      this.filterVideoProcess.pageRecords = event.pageSize;
    }
    else{
      this.filterVideoProcess.pageIndex = String(event.pageIndex + 1);
    }
    this.getProcessVideos(false);
  }

  getProcessVideos(init: boolean): void{
    this.showProbresBar = true;
    this.filterVideoProcess.tokenDelProcesoCod = this.videoId;
    this.apiService.getProcessVideos(this.filterVideoProcess, init).subscribe(
      (requestVideos: any) => {
        this.videos = requestVideos.Data;
        this.pagination = requestVideos.Pagination[0];
        this.showProbresBar = false;
        this.procesoCod = this.videos[0].ProcesoCod;
      },
      err => {
        console.log(err)
        this.showProbresBar = false;
      });
  }

  showFilterAdvanced(): void{
    this.filterAdvanced = !this.filterAdvanced;
  }

  cleanFilter():void{
    this.filterVideoProcess = {
      usePagination: true,
      tokenDelProcesoCod: '',
      nombreJuez: '',
      pageRecords: this.filterVideoProcess.pageRecords,
      pageIndex: '1',
      useDateFilter: '',
      lowerDate: '',
      upperDate: '',
      etiquetas: '',
    }
    this.getProcessVideos(false)
  }

}
