 export const environment = {
  production: true,
  urlApiVideos: {
     //url: 'http://172.27.64.25/Api',
    // url: 'https://tecgodev.com',
    // url: 'http://gestorvideospruebas2.ramajudicial.local',
    url: 'https://apigestionaudiencias1.ramajudicial.gov.co',
    // url: 'https://apigestionaudiencias1.ramajudicial.gov.co',
    user: 'USR_A3FAA51025C84819A4ABF550DB4A3ECF',
    password: 'D1B2290324EA479A8AB74B92EE1A82FC'
  },
  title: 'Gestión de videos',
  pageSizes: ['10', '20', '30'],
  urlApiIP: "https://api.ipify.org/?format=json"
};
