<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue">
</app-toolbar>
<div class="main-content">

  <form #parent>
    <div class="row">
      <mat-form-field appearance="standard">
        <mat-label>Módulos</mat-label>
        <mat-select [(ngModel)]="filterLog.modulo"
                    [ngModelOptions]="{standalone: true}" name="view">
          <mat-option *ngFor="let module of modules" [value]="module.value">
            {{module.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Usuario</mat-label>
        <input matInput type="text"
               [(ngModel)]="filterLog.usuario"
               [ngModelOptions]="{standalone: true}">
        <button mat-button *ngIf="filterLog.usuario" matSuffix
                mat-icon-button aria-label="Clear"
                (click)="filterLog.usuario=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field class="example-form-field">
        <mat-label>Acción</mat-label>
        <input matInput type="text"
               [(ngModel)]="filterLog.descripcion" [ngModelOptions]="{standalone: true}">
        <button mat-button *ngIf="filterLog.descripcion" matSuffix
                mat-icon-button aria-label="Clear"
                (click)="filterLog.descripcion=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="example-form-field">
        <mat-label>Rango de fechas de búsqueda</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Fecha inicial"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="filterLog.lowerDate"
                 class="example-full-width">
          <input matEndDate placeholder="Fecha final"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="filterLog.upperDate"
                 class="example-full-width">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker disabled="false" touchUi></mat-date-range-picker>
      </mat-form-field>



      <button mat-button mat-flat-button (click)="startGetLogs()" color="accent">
        <mat-icon>search</mat-icon> Buscar
      </button>
      <button mat-button mat-flat-button (click)="exportLogsCSV()" color="primary">
        <mat-icon>file_download</mat-icon> Exportar CSV
      </button>

    </div>
  </form>
  <mat-paginator [length]="pagination.TotalRows"
                 [pageSize]="filterLog.pageRecords"
                 [pageSizeOptions]="pageSizes"
                 (page)="pageOnChange($event)" >
  </mat-paginator>
  <div class="row">
    <table mat-table [dataSource]="logs" class="centered">
      <ng-container matColumnDef="Usuario">
        <th mat-header-cell *matHeaderCellDef> Usuario </th>
        <td mat-cell *matCellDef="let log"> {{log.Usuario}} </td>
      </ng-container>

      <ng-container matColumnDef="Modulo">
        <th mat-header-cell *matHeaderCellDef> Módulo </th>
        <td mat-cell *matCellDef="let log"> {{log.Modulo | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="IpCliente">
        <th mat-header-cell *matHeaderCellDef > Ip </th>
        <td mat-cell *matCellDef="let log"> {{ log.IpCliente }} </td>
      </ng-container>

      <ng-container matColumnDef="TokenSesion">
        <th mat-header-cell *matHeaderCellDef style="width: 15%"> Token </th>
        <td mat-cell *matCellDef="let log"> {{log.TokenSesion}} </td>
      </ng-container>

      <ng-container matColumnDef="FechaRegistro">
        <th mat-header-cell *matHeaderCellDef> Fecha ejecución </th>
        <td mat-cell *matCellDef="let log"> {{ log.FechaRegistro | date:"yyyy/MM/dd HH:mm" }} </td>
      </ng-container>

      <ng-container matColumnDef="Descripcion">
        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;"> Descripción </th>
        <td mat-cell *matCellDef="let log"> {{ log.Descripcion  }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>


</div>

<app-footer></app-footer>
