import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {environment} from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import videojs from 'video.js';
import { VideoDetail, InfoShare, RequestRUShare } from "../../models/video";

import {LogsService} from "../../services/logs.service";
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  url_download_file = environment.urlApiVideos.url + '/api/Portal/Adjuntos/DownloadFile?adjuntoCod='
  module = "compartidos"
  urlApi = environment.urlApiVideos.url;
  displayedColumnsAdjunts = ["Descripcion", "FechaRegistro", "gestion"]
  notVideo: boolean = true
  token:string = ''
  video: VideoDetail = {
    CiudadCodAudiencia: "",
    EntidadCodAudiencia: "",
    EspecialidadCodAudiencia: "",
    Estado: "",
    Etiquetas: [],
    FechaGrabacion: "",
    FechaRegistro: "",
    GrabacionCod: 0,
    HoraGrabacion: "",
    JuzgadoCodAudiencia: "",
    JuzgadoNombre: "",
    NombreArchivo: "",
    NombreSala: "",
    NumeroGrabacion: "",
    OrigenGrabacion: "",
    ProcesoCod: "",
    RutaDecodificado: "",
    RutaOriginal: "",
    TipoAudiencia: "",
    VideosCompartidos: [],
    Adjuntos: [],
    VideoCompartidoUsuarios: [],
    DistritoCod: '',
    DistritoNombre: '',
    AreaCod: '',
    AreaNombre: '',
    JurisdiccionCod: '',
    JurisdiccionNombre: '',
    NombreJuez: '',
    TiempoEtiquetas: [],
    CaracterReservado: true,
    Duracion: '',
    EtiquetasLista: ''
  }
  requestSave: RequestRUShare = {
    ExecutionMessage: "",
    ExecutionStatus: false,
    ProcedureMessage: "",
    ProcedureStatus: false,
    ProcedureValue: {
      CiudadCodAudiencia: "",
      EntidadCodAudiencia: "",
      EspecialidadCodAudiencia: "",
      Estado: "",
      Etiquetas: [],
      FechaGrabacion: "",
      FechaRegistro: "",
      GrabacionCod: 0,
      HoraGrabacion: "",
      JuzgadoCodAudiencia: "",
      JuzgadoNombre: "",
      NombreArchivo: "",
      NombreSala: "",
      NumeroGrabacion: "",
      OrigenGrabacion: "",
      ProcesoCod: "",
      RutaDecodificado: "",
      RutaOriginal: "",
      TipoAudiencia: "",
      VideosCompartidos: [],
      Adjuntos: [],
      VideoCompartidoUsuarios: [],
      DistritoCod: '',
      DistritoNombre: '',
      AreaCod: '',
      AreaNombre: '',
      JurisdiccionCod: '',
      JurisdiccionNombre: '',
      NombreJuez: '',
      TiempoEtiquetas: [],
      CaracterReservado: true,
      Duracion: '',
      EtiquetasLista: ''
    }
  }
  infoShare: InfoShare = {
    "VideosCompartidoCod": 0,
    "GrabacionCod": 0,
    "Dias": 0,
    "FechaLimite": "",
    "ReproduccionesMax": 0,
    "NroReproducciones": 0,
    "Token": "",
    "Usuario": "",
    "CorreoCompartido": "",
    "DescargarVideo": false,
    "DescargarAdjunto": false,
    "FechaRegistro": ""
  }
  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;
  player: videojs.Player;
  options = {
    autoplay: true,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4',
        poster: ''
      }
        ]
  };
  poster:string = ''
  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              public logService: LogsService,
              private cookieService: CookieService) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.token = params.token;
      let tokenCookie = this.cookieService.get("tokenShare")
      if(tokenCookie == "" || tokenCookie != this.token)
      {
        this.apiService.GetInfoShare(this.token).subscribe(
          request =>{
            this.requestSave = <RequestRUShare> request;
            if(this.requestSave.ExecutionStatus && this.requestSave.ProcedureStatus){
              this.notVideo = false
              this.video = <VideoDetail>this.requestSave.ProcedureValue;
              this.infoShare = <InfoShare> this.video.VideosCompartidos[0];
              let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
              this.options.sources[0].src = src_video;
              const type = this.video.NombreArchivo.split('.')[1]
              if(type === 'mp3'){
                this.options.sources[0].type = 'audio/mp3'
                this.poster = '../../../assets/poster.jpg'
              }else{
                this.options.sources[0].type = 'video/mp4'
                this.poster = ''
              }
              this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
                console.log('onPlayerReady', this);
              })
              let now = new Date()
              now.setHours(now.getHours() + 12)
              this.cookieService.set("tokenShare", this.token, now )
              localStorage.setItem("videoShare", JSON.stringify(request.ProcedureValue))
              this.logService.createLog(this.module,
                "visualización del video " +this.video.NombreArchivo).subscribe(
                request =>console.log("ok"),
                error => console.error(error)
              )

            }
            else{
              this.notVideo = true;
              this.video.GrabacionCod = -1
            }
          },
          error => {
            console.log(error);
          }
        )
      }
      else{
        this.video = <VideoDetail>JSON.parse(localStorage.getItem("videoShare"))
        this.infoShare = <InfoShare>this.video.VideosCompartidos[0]
        this.options.sources[0].src = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo;
        const type = this.video.NombreArchivo.split('.')[1]
              if(type === 'mp3'){
                this.options.sources[0].type = 'audio/mp3'
                this.poster = '../../../assets/poster.jpg'
              }else{
                this.options.sources[0].type = 'video/mp4'
                this.poster = ''
              }
        this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
      }
    })
  }

  updateCurrentTime(time: number): void{
    this.player.currentTime(time)
    this.player.play()
  }
}
