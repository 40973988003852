import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Label, RequestRU, TiempoEtiqueta, VideoDetail} from "../../models/video";
import {environment} from "../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {LogsService} from "../../services/logs.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import videojs from 'video.js';

@Component({
  selector: 'app-tag-video',
  templateUrl: './tag-video.component.html',
  styleUrls: ['./tag-video.component.scss']
})
export class TagVideoComponent implements OnInit {
  icon_play: string = 'pause'
  title_play: string = 'Pausar video para guardar la etiqueta'
  process_create: boolean = false
  data_tag: TiempoEtiqueta = {
    TiempoEtiquetaCod: 0,
    GrabacionCod: '',
    Segundos: 0,
    Descripcion: '',
    TiempoMostrar: '',
    FechaRegistro: ''
  }
  token = ''
  breadcrumbs = []
  displayedColumnsTags = ['Minuto', 'Nombre', 'gestion']
  videoId:string = ''
  formCreate: FormGroup;
  video: VideoDetail = {
    CiudadCodAudiencia: "",
    EntidadCodAudiencia: "",
    EspecialidadCodAudiencia: "",
    Estado: "",
    Etiquetas: [],
    FechaGrabacion: "",
    FechaRegistro: "",
    GrabacionCod: 0,
    HoraGrabacion: "",
    JuzgadoCodAudiencia: "",
    JuzgadoNombre: "",
    NombreArchivo: "",
    NombreSala: "",
    NumeroGrabacion: "",
    OrigenGrabacion: "",
    ProcesoCod: "111",
    RutaDecodificado: "",
    RutaOriginal: "",
    TipoAudiencia: "",
    VideosCompartidos: [],
    Adjuntos: [],
    VideoCompartidoUsuarios: [],
    DistritoCod: '',
    DistritoNombre: '',
    AreaCod: '',
    AreaNombre: '',
    JurisdiccionCod: '',
    JurisdiccionNombre: '',
    NombreJuez: '',
    TiempoEtiquetas: [],
    CaracterReservado: true,
    Duracion: '',
    EtiquetasLista: ''
  }
  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;
  player: videojs.Player;
  module = "tags de videos"
  options = {
    autoplay: true,
    controls: false,
    sources: [
      {
        src: '',
        type: 'video/mp4',
        poster: ''
      }
    ]
  };
  poster:string = ''
  ProcedureValue = {}
  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              private router: Router,
              public logService: LogsService,
              private formBuilder: FormBuilder,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if(this.token != null)
    {
      this.activeRoute.params.subscribe(params => {
        this.videoId = params.id;
        this.data_tag.GrabacionCod = this.videoId
        this.GetVideoDetail()
        let formT = localStorage.getItem('formTransliterator')
        if (formT == null){
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId},
            {name: 'Tags de reproducción de video', url: '/tagsvideo/'+ this.videoId}
          ]
        }else{
          this.breadcrumbs = [
            {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
            {name: 'Búsqueda por transliteración', url: '/transliterator'},
            {name: 'Detalle del video', url: '/detail/'+ this.videoId},
            {name: 'Tags de reproducción de video', url: '/tagsvideo/'+ this.videoId}
          ]
        }

      })
      this.formCreate = this.formBuilder.group({
        Nombre: ['', {validators: [Validators.required]}]
      })
    }
    else
      this.exit()
  }

  GetVideoDetail(): void{
    this.apiService.GetInfoVideo(this.videoId).subscribe(
      request=>{
        this.video = <VideoDetail> request;
        let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
        this.options.sources[0].src = src_video;
        const type = this.video.NombreArchivo.split('.')[1]
        if(type === 'mp3'){
          this.options.sources[0].type = 'audio/mp3'
          this.poster = '../../../assets/poster.jpg'
        }else{
          this.options.sources[0].type = 'video/mp4'
          this.poster = ''
        }
        this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
          console.log('onPlayerReady', this);
        })
        this.logService.createLog(this.module,
          "visualización del video " +this.video.NombreArchivo).subscribe(
          request =>console.log("ok"),
          error => console.error(error)
        )
        this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      },
      error => {

      }
    )
  }
  CreateTag(): void{
    this.process_create = true

    this.data_tag.Segundos = Math.trunc(this.player.currentTime())
    this.apiService.RegisterTagVideo(this.data_tag).subscribe(
      request => {
        let requestSave = <RequestRU> request;
        let msg: string
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          var str_tag = this.data_tag.Descripcion + ' video: '+this.video.GrabacionCod
          this.logService.createLog(this.module,
            "Creación de tag:" +str_tag).subscribe(
            request => console.log("ok"),
            error => console.error(error)
          )
          this.GetVideoDetail()
          this.clearFormShare()
          msg = 'El tag se ha guardado con éxito'
        }
        else
          msg = 'Error al intentar crear el tag'
        this.showMsg(msg);
        this.process_create = false
      }, error => {
        console.error(error)
      }
    )
  }
  DeleteTag(tag: TiempoEtiqueta): void{
    this.process_create = true
    this.apiService.RemoveTagVideo(tag).subscribe(
      request => {
        let requestSave = <RequestRU> request;
        let msg: string
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          var str_tag = this.data_tag.Descripcion + ' video: '+this.video.GrabacionCod
          this.logService.createLog(this.module,
            "Eliminación de tag:" +str_tag).subscribe(
            request => console.log("ok"),
            error => console.error(error)
          )
          this.GetVideoDetail()
          msg = 'El tag se ha eliminado con éxito'
        }
        else
          msg = 'Error al intentar eliminar el tag'
        this.showMsg(msg);
        this.process_create = false
      }, error => {

      }
    )
  }
  clearFormShare(): void{
    this.data_tag.Descripcion = ''
  }
  showMsg(msg: string):void{
    this._snackBar.open(msg, null,
      {
        duration: 8000,
        panelClass: 'green-snackbar',
        horizontalPosition: 'end',
        verticalPosition: 'top',
      })
  }

  pause_video(): void{
    if(this.icon_play == 'play_arrow'){
      this.player.play()
      this.icon_play = 'pause'
      this.title_play = 'Pausar video para guardar la etiqueta'
    }
    else{
      this.player.pause()
      this.icon_play = 'play_arrow'
      this.title_play = 'Reproducir video'
    }
  }

  forward_10():void{
    this.player.currentTime(this.player.currentTime()+10)
    this.player.play()
    this.icon_play = 'pause'
    this.title_play = 'Pausar video para guardar la etiqueta'
  }
  forward_30():void{
    this.player.currentTime(this.player.currentTime()+30)
    this.player.play()
    this.icon_play = 'pause'
    this.title_play = 'Pausar video para guardar la etiqueta'
  }
  replay_10():void{
    this.player.currentTime(this.player.currentTime()-10)
    this.player.play()
    this.icon_play = 'pause'
    this.title_play = 'Pausar video para guardar la etiqueta'
  }
  replay_30():void{
    this.player.currentTime(this.player.currentTime()-30)
    this.player.play()
    this.icon_play = 'pause'
    this.title_play = 'Pausar video para guardar la etiqueta'
  }


  exit(): void{
    this.router.navigate(['/'])
  }

}
