import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VideoDetail} from "../../models/video";
import {environment} from "../../../environments/environment";
import videojs from 'video.js';

@Component({
  selector: 'app-card-video',
  templateUrl: './card-video.component.html',
  styleUrls: ['./card-video.component.scss']
})
export class CardVideoComponent implements OnInit {
  urlApi = environment.urlApiVideos.url;
  options = {
    autoplay: false,
    controls: false,
    sources: [
      {
        src: '',
        type: 'video/mp4'
      }
    ]
  };
  @ViewChild('idVideo', {static: true}) idVideo: ElementRef;
  player: videojs.Player;
  constructor() { }

  ngOnInit(): void {
    let src_video = environment.urlApiVideos.url+'/api/filevideo/descargar?videoName='+this.video.NombreArchivo
    this.options.sources[0].src = src_video;
    this.player = videojs(this.idVideo.nativeElement, this.options, function onPlayerReady() {
      console.log('onPlayerReady', this);
      this.play()
      this.currentTime(10)
      this.pause()
    })
  }
  @Input('video') video: VideoDetail
  @Input("ProcedureValue") ProcedureValue

}
