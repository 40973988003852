<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue">
</app-toolbar>
<div class="main-content">
  <form class="example-form" (submit)="getVideoShare()">
    <div class="row">
      <div class="col s12">
        <mat-form-field class="full-width">
          <mat-label>Buscar por codigo de proceso</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput type="text"
                 [(ngModel)]="filterVideo.ProcesoCod"
                 [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="showProbresBar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="row">
      <div class="col s4 offset-s2 m3 offset-m3 l2 offset-l4 xl2 offset-xl4">
        <button mat-button mat-flat-button
                class="background-color-buttom" type="submit">
          Buscar <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="col s4 m3 l2 xl1">
        <button mat-button mat-flat-button
                (click)="cleanFilter()" type="button">
          Borrar formulario <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </form>

  <mat-paginator [length]="pagination.TotalRows"
                 [pageSize]="filterVideo.pageRecords"
                 [pageSizeOptions]="pageSizes"
                 (page)="pageOnChange($event)" showFirstLastButtons>
  </mat-paginator>

  <app-svideoshare [videos]="videos" *ngIf="videos.length > 0"></app-svideoshare>
  <mat-card *ngIf="videos.length == 0">La búsqueda no encontró grabaciones compartidas</mat-card>

</div>


<app-footer></app-footer>









