import {Component, OnInit} from '@angular/core';
import {LogsService} from "../../services/logs.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Gestión de videos';
  constructor(public logsService: LogsService) {
  }

  ngOnInit(): void {
    let ip = localStorage.getItem("ip")
    if(ip == null)
      this.logsService.getIPAddress();

  }
}
