import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {FilterVideo, RequestVideo, Video} from "../../models/video";
import {RequestToken} from "../../models/user";
import {Pagination} from "../../models/host";
import {ActivatedRoute, Router} from "@angular/router";
import {OdooService} from "../../services/odoo.service";
import {ApiService} from "../../services/api.service";
import {LogsService} from "../../services/logs.service";
import {Validators} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-transliterated',
  templateUrl: './transliterated.component.html',
  styleUrls: ['./transliterated.component.scss']
})
export class TransliteratedComponent implements OnInit {
  showProbresBar: boolean = false
  pageSizes = environment.pageSizes;
  token = ''
  user_id = ''
  color = '#FFFFFF'
  breadcrumbs = []
  filterVideo = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    juzgadoCodList: 'ALL',
    textTrasliteration: ''
  }
  videos: Video[] = []
  user:RequestToken
  pagination: Pagination = {
    CurrentPage: 0,
    TotalPages: 0,
    StartRow: 0,
    FinalRow: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }

  ProcedureValue = {
    "id": 0,
    "name": "",
    "type": "secretary",
    "judged": "",
    "judged_id": "",
    "office": "",
    "entity_name": "",
    "specialty_name": "",
    "email": "",
    "name_complete": ""
  }
  requestVideo: RequestVideo

  constructor(private activeRoute: ActivatedRoute,
              public apiService: ApiService,
              public logService: LogsService,
              private router: Router) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if(this.token != null)
    {
      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Búsqueda por transliteración', url: '/transliterator'},
      ]
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      if(this.ProcedureValue.type != 'scheduler'){
        this.filterVideo.juzgadoCodList = this.ProcedureValue.judged_id
      }
      let txt = localStorage.getItem('formTransliterator')
      if (txt != null){
        this.filterVideo.textTrasliteration = txt
        this.getVideo()
      }
    }
    else{
      this.exit()
    }
  }

  exit(): void{
    this.router.navigate(['/'])
  }

  getVideo(): void{
    this.showProbresBar = true
    this.apiService.getVideosTransliterator(this.filterVideo).subscribe(
      requestVideos => {
        this.requestVideo = requestVideos;
        this.videos = this.requestVideo.Data;
        this.pagination = this.requestVideo.Pagination[0]
        this.showProbresBar = false
      },
      err => {
        console.log(err)
        this.showProbresBar = false
      }
    )
  }
  cleanFilter():void{
    this.filterVideo.textTrasliteration = ''
    this.videos = []
  }

  pageOnChange(event: PageEvent){
    this.showProbresBar = true;
    if(this.filterVideo.pageRecords != event.pageSize){
      this.filterVideo.pageIndex = '1';
      this.filterVideo.pageRecords = event.pageSize;
    }
    else{
      this.filterVideo.pageIndex = String(event.pageIndex + 1);
    }

    this.getVideo()
  }

}
