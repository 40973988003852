<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue"></app-toolbar>
<div class="main-content">
  <div class="row">
    <div class="col xl8 l8 m12 s12">
      <video
        class="video-js vjs-big-play-centered pgg-video vjs-16-9"
        controls
        playsinline
        preload="none" #idVideo [poster]="poster">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
    <div class="col xl4 l4 m12 s12">
      <form [formGroup]="formCreate" (submit)="CreateTag()">
        <div class="row" *ngIf="process_create">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <div class="row">
          <div class="col s12 m6 offset-m3 l12 xl12">
            <mat-form-field class="example-full-width" appearance="standard">
              <mat-label>Palabra clave</mat-label>
              <input matInput
                     formControlName="Nombre"
                     required [(ngModel)]="data_tag.Nombre">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col s12 m6 offset-m4 l12 xl12">
            <button mat-button mat-flat-button
                    [disabled]="formCreate.invalid"
                    class="aling-checkbox-button background-color-buttom">
              <mat-icon>save</mat-icon> Compartir
            </button>
            <button mat-button mat-flat-button type="button"
                    class="aling-checkbox-button" (click)="clearFormShare()">
              Borrar formulario <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <mat-tab-group>
        <mat-tab label="Lista de palabras claves">
          <table mat-table [dataSource]="video.Etiquetas" class="centered" *ngIf="video.Etiquetas.length > 0">
            <ng-container matColumnDef="Nombre">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let tag"> {{tag.Nombre}} </td>
            </ng-container>
            <ng-container matColumnDef="gestion">
              <th mat-header-cell *matHeaderCellDef> Gestión </th>
              <td mat-cell *matCellDef="let tag">
                <a mat-button matTooltip="Eliminar palabra clave"
                   mat-flat-button color="primary"
                   (click)="DeleteTag(tag)">
                  <mat-icon>close</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTags"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTags;"></tr>
          </table>
          <p *ngIf="video.Etiquetas.length == 0">
            No hay palabras claves de video
          </p>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>
</div>

<app-footer></app-footer>
