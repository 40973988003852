<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" >
</app-toolbar>
<form class="example-form" (submit)="getProcessVideos(false)">

  <!-- <div class="row" *ngIf="ProcedureValue.type === 'secretary' && ProcedureValue.extra_permission !== ''">
    <div class="col s12 m6 l10 xl10">
      <ng-autocomplete
        [data]="optionsJudged"
        [searchKeyword]="keyFilter"
        (selected)='selectJudgedEvent($event)'
        (inputFocused)='onFocusedJudgedEvent($event)'
        (inputChanged)='onChangeJudgedEvent($event)'
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
        #judgedInput2
        notFoundText="No hay despachos"
        placeholder="Despacho">
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.Nombre + '('+item.JuzgadoCod+')'" *ngIf="item.Nombre.length"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
  </div> -->

  <div class="row p">
    <div class="col s10">
      <div class="col s12">
        <mat-form-field class="full-width">
          <mat-label>Código de proceso</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput type="text"
                 [(ngModel)]="procesoCod"
                 [ngModelOptions]="{standalone: true}"
                 [disabled]="true">
        </mat-form-field>
      </div>
      <ng-container *ngIf="filterAdvanced">
        <div class="row">
          <div class="col s6 m3 l2 xl2">
            <mat-form-field class="example-full-width">
              <mat-label>Fecha inicial</mat-label>
              <input matInput [matDatepicker]="picker"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="filterVideoProcess.lowerDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="year" ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s6 m3 l2 xl2">
            <mat-form-field class="example-full-width" >
              <mat-label>Fecha final</mat-label>
              <input matInput [matDatepicker]="picker2"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="filterVideoProcess.upperDate">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 startView="year" ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m6 l4 xl4">
            <mat-form-field class="example-full-width">
              <mat-label>Juez</mat-label>
              <input matInput [(ngModel)]="filterVideoProcess.intervinienteNombre" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
          <div class="col s12 m6 l4 xl4">
            <mat-form-field class="example-full-width">
              <mat-label>Palabras claves</mat-label>
              <input matInput [(ngModel)]="filterVideoProcess.etiquetas" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col s2">
      <button mat-button mat-flat-button
              matTooltip="Búsqueda avanzada"
              class="background-color-buttom"
              type="button"
              (click)="showFilterAdvanced()" >
        <mat-icon>videocam</mat-icon>
        <ng-container *ngIf="filterAdvanced else arrow_up">
            <span mat-icon-button>
              <mat-icon>keyboard_arrow_up</mat-icon>
            </span>
        </ng-container>
        <ng-template #arrow_up>
            <span mat-icon-button>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
        </ng-template>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col s4 offset-s2 m3 offset-m3 l2 offset-l4 xl2 offset-xl4">
      <button mat-button mat-flat-button
              class="background-color-buttom" type="submit">
        Buscar <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="col s4 m3 l2 xl2">
      <button mat-button mat-flat-button
              (click)="cleanFilter()" type="button">
        Borrar formulario <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="col s2 m3 l4 xl3" style="color: rgb(0,113,102);" >
      Total de Grabaciones: {{pagination.TotalRows | number}}

    </div>
  </div>
  <div class="row" *ngIf="showProbresBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</form>
<mat-paginator [length]="pagination.TotalRows"
                 [pageSize]="filterVideoProcess.pageRecords"
                 [pageSizeOptions]="pageSizes"
                 (page)="pageOnChange($event)" showFirstLastButtons>
  </mat-paginator>
  <app-sprocessvideo [videos]="videos" *ngIf="videos.length > 0"></app-sprocessvideo>
  <mat-card *ngIf="videos.length == 0">La búsqueda no encontró grabaciones compartidas</mat-card>
<app-footer></app-footer>
