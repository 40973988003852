import {Component, OnInit} from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { FilterLog, Log, RequestLog } from '../../models/logs'
import { LogsService } from '../../services/logs.service'
import { environment } from '../../../environments/environment'

import { PageEvent } from '@angular/material/paginator'
import {ActivatedRoute, Router} from "@angular/router";
import {Pagination} from "../../models/host";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {
  modules = [
    {value: 'ALL', viewValue: 'Todos'},
    {value: 'adjuntos', viewValue: 'Adjuntos'},
    {value: 'compartidos', viewValue: 'Reproducción de compartidos'},
    // {value: 'compartidos usuarios', viewValue: 'Reproducción de compartidos de usuarios'},
    {value: 'detalle video', viewValue: 'Detalle del video'},
    {value: 'detalle video compartido', viewValue: 'Detalle del video compartido'},
    // {value: 'equipos', viewValue: 'Equipos'},
    // {value: 'logs', viewValue: 'Logs'},
    {value: 'videos', viewValue: 'Videos'},
    {value: 'palabras claves', viewValue: 'Palabras claves'},
    {value: 'tags de videos', viewValue: 'Tags de Videos'},
    {value: 'transliteración', viewValue: 'Transliteración'},
  ];
  pagination: Pagination = {
    CurrentPage: 1,
    TotalPages: 0,
    StartRow: 0,
    FinalRow: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }
  breadcrumbs = []
  token: string = ''
  displayedColumns = ["Usuario", "Modulo", "IpCliente",
                      "FechaRegistro", "Descripcion"]
  requestLogs: RequestLog

  pageSizes = environment.pageSizes
  logs: Log[]
  public filterLog = <FilterLog> {
    usePagination: true,
    pageIndex: "1",
    pageRecords: "10",
    orderField: "FechaRegistro",
    orderDirection: "DESC",
    usuario: "",
    modulo: "ALL",
    descripcion: "",
    useDateFilter: '0',
    lowerDate: '',
    upperDate: ''
  }
  ProcedureValue = {}
  constructor( public logsService: LogsService,
               private router: Router) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    // validar datos de usuario
    if(this.token != null)
    {
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      this.startGetLogs()
      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Logs', url: '/registers/'},
      ]
    }
    else
      this.exit()

  }

  startGetLogs(){
    this.logsService.getLogs(this.filterLog).subscribe(
      requestLogs => {
        this.requestLogs = <RequestLog>requestLogs;
        this.logs = this.requestLogs.Data;
        this.pagination = this.requestLogs.Pagination[0]
      },
      err => console.log(err)
    )
  }

  pageOnChange(event: PageEvent){
    if(this.filterLog.pageRecords != String(event.pageSize)) {
      this.filterLog.pageIndex = '1';
      this.filterLog.pageRecords = String(event.pageSize)
    }
    else
      this.filterLog.pageIndex = String(event.pageIndex + 1);
    this.startGetLogs()
  }

  exit(): void{
    this.router.navigate(['/'])
  }

  exportLogsCSV(): void{

    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      // showTitle: true,
      showTitle: false,
      title: 'Reporte de datos de LOGS',
      useTextFile: false,
      useBom: true,
      // useKeysAsHeaders: true,
      useKeysAsHeaders: false,
      headers: ['ID', 'Usuario', 'Token', 'Modulo', 'Descripción', 'IP', 'Fecha/Hora registro']
    };
    this.logsService.getLogsCsv(this.filterLog).subscribe(
      requestLogs => {
        let csv_logs = <RequestLog>requestLogs;
        // this.logs = ;
        // this.pagination = this.requestLogs.Pagination[0]
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(csv_logs.Data);
      },
      err => console.log(err)
    )


  }


}
