import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'

import {
  FilterVideo,
  RequestVideo,
  VideoShare,
  VideoDetail,
  RequestRU,
  RequestRUShare,
  ModelDateFilter, VideoShareInterno, FilterVideoShare, Label,
  TiempoEtiqueta,
  ReservateVideo,
  FilterVideoProcess,
  RequestProcess
} from "../models/video";
import { FilterHost, Host, RequestHost  } from "../models/host";
import { environment } from '../../environments/environment'
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { DateCompany, TimeConsumed, DailyConsumption,AccumulatedConsumption, MonthlyConsumption, Consumption, UserValidation } from "../models/statistics";


@Injectable({
  providedIn: 'root'
})

export class ApiService {
  urlApi = environment.urlApiVideos.url;
  headers: HttpHeaders
  basic: string
  constructor(private _http: HttpClient) {
    this.createBtoaLogin()
  }

  createBtoaLogin(): void {
    this.basic = btoa(environment.urlApiVideos.user+':'+environment.urlApiVideos.password)
  }

  //get ReservateVideo

  getReservateVideo(processCod: string): Observable<ReservateVideo>{
    return this._http.get<ReservateVideo>(
      this.urlApi +
      '/api/Portal/Grabaciones/GetProcessCodReserved?ProcessCod=' + processCod,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  // end ReservateVideo
  //get video
  getVideos(filterVideo: FilterVideo, init: boolean): Observable<RequestVideo> {
    let dateFilterVideo = '1'
    let upperDate: any = ''
    let lowerDate: any = ''
    if(init && filterVideo.lowerDate !== '' && filterVideo.upperDate !== ''){
      let date_now = new Date()
      try{
        lowerDate = filterVideo.lowerDate.toISOString().substring(0, 10);
      } catch (e) {
        lowerDate = date_now.toISOString().substring(0, 10);
      }
      try{
        upperDate = filterVideo.upperDate.toISOString().substring(0, 10);
      } catch (e) {
        upperDate = date_now.toISOString().substring(0, 10);
      }
    }
    else if(filterVideo.lowerDate !== '' && filterVideo.upperDate === ''){
      let date_now = new Date()
      try{
        lowerDate = filterVideo.lowerDate.toISOString().substring(0, 10);
      } catch (e) {
        lowerDate = date_now.toISOString().substring(0, 10);
      }
      try{
        upperDate = filterVideo.upperDate.toISOString().substring(0, 10);
      } catch (e) {
        upperDate = date_now.toISOString().substring(0, 10);
      }
    }
    else{
      try{
        lowerDate = filterVideo.lowerDate._d.toISOString().substring(0, 10);
        upperDate = filterVideo.upperDate._d.toISOString().substring(0, 10);
      } catch (e) {
        let date_now = new Date()
        upperDate = date_now.toISOString().substring(0, 10);;
        lowerDate = new Date(1900,1,1).toISOString().substring(0, 10);
        dateFilterVideo = '0'
      }
    }
    filterVideo.useDateFilter = dateFilterVideo
    localStorage.setItem('formSearch', JSON.stringify(filterVideo))
    return this._http.get<RequestVideo>(
      this.urlApi + '/api/Portal/Grabaciones/TableView?usePagination=true&pageIndex=' +
      filterVideo.pageIndex+'&pageRecords='+filterVideo.pageRecords+'&orderField='+
      filterVideo.orderField+'&orderDirection='+filterVideo.orderDirection+
      "&juzgadoCodList="+filterVideo.juzgadoCodList+"&useDateFilter="+dateFilterVideo+"&lowerDate="+lowerDate
      +"&upperDate="+upperDate+"&intervinienteNombre="+
      '&procesoCod='+filterVideo.procesoCod+'&etiquetas='+filterVideo.etiqueta+'&distritoCod='+filterVideo.district
      +'&areaCod='+filterVideo.area+'&nombreJuez='+filterVideo.intervinienteNombre+'&UserId='+localStorage.getItem("user_id"),
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
      )
  }
  GetInfoVideo(id: string): Observable<VideoDetail>{
    return this._http.get<VideoDetail>(
      this.urlApi + '/api/Portal/Grabaciones/Read?GrabacionCod='+id,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  //end get video

  //get process videos
  getProcessVideos(filterVideoProcess: FilterVideoProcess, init: boolean): Observable<RequestProcess> {
    let dateFilterVideo = '1'
    let upperDate: any = ''
    let lowerDate: any = ''
    console.log(filterVideoProcess)
    if(init && filterVideoProcess.lowerDate !== '' && filterVideoProcess.upperDate !== ''){
      let date_now = new Date()
      try{
        lowerDate = filterVideoProcess.lowerDate.toISOString().substring(0, 10);
      } catch (e) {
        lowerDate = date_now.toISOString().substring(0, 10);
      }
      try{
        upperDate = filterVideoProcess.upperDate.toISOString().substring(0, 10);
      } catch (e) {
        upperDate = date_now.toISOString().substring(0, 10);
      }
    }
    else if(filterVideoProcess.lowerDate !== '' && filterVideoProcess.upperDate === ''){
      let date_now = new Date()
      try{
        lowerDate = filterVideoProcess.lowerDate.toISOString().substring(0, 10);
      } catch (e) {
        lowerDate = date_now.toISOString().substring(0, 10);
      }
      try{
        upperDate = filterVideoProcess.upperDate.toISOString().substring(0, 10);
      } catch (e) {
        upperDate = date_now.toISOString().substring(0, 10);
      }
    }
    else{
      try{
        lowerDate = filterVideoProcess.lowerDate._d.toISOString().substring(0, 10);
        upperDate = filterVideoProcess.upperDate._d.toISOString().substring(0, 10);
      } catch (e) {
        let date_now = new Date()
        upperDate = date_now.toISOString().substring(0, 10);;
        lowerDate = new Date(1900,1,1).toISOString().substring(0, 10);
        dateFilterVideo = '0'
      }
    }
    filterVideoProcess.useDateFilter = dateFilterVideo
    localStorage.setItem('formSearch', JSON.stringify(filterVideoProcess))
    return this._http.post<RequestProcess>(
      this.urlApi + '/api/Portal/VideosCompartidos/ValidateProcess',
      filterVideoProcess,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
      )
  }
  //end get process videos
  //filter video
  getArea(): Observable<any[]>{
    return this._http.get<any[]>(
      this.urlApi+'/api/Portal/Areas/ListView?nombre=',
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      })
  }
  getDistricts(name: string=''): Observable<any[]>{
    return this._http.get<any[]>(this.urlApi+'/api/Portal/Distritos/ByArea_ListView?areaCod='+name+'&nombre=',
    {
      headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
    })
  }

  getJuzged(district: any, area: any): Observable<any[]>{
    return this._http.get<any[]>(
      this.urlApi +
      '/api/Portal/Juzgados/ByAreaDistritoCodListView?distritoCod='+
      district+'&areaCod='+area+'&nombre=',
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }

  getJuzgedOtherUser(): Observable<any[]>{
    return this._http.get<any[]>(
      this.urlApi +
      '/api/Portal/Juzgados/ByUserListView?UserId='+localStorage.getItem("user_id"),
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }

  // end filter video

  //share externo
  ShareVideo(video: VideoShare,etiquetaLista: string): Observable<RequestRU>{
    console.log(video);
    console.log(etiquetaLista)
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/VideosCompartidos/Insert',
      {
        GrabacionCod: video.GrabacionCod,
        Dias: video.Dias,
        ReproduccionesMax: video.ReproduccionesMax,
        Usuario: video.Usuario,
        CorreoCompartido: video.CorreoCompartido,
        DescargarAdjunto: video.descargarAdjunto,
        DescargarVideo: video.descargarVideo,
        CompartirTodoslosVideos: video.CompartirTodoslosVideos,
        EtiquetaLista: etiquetaLista
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  GetInfoShare(token: string): Observable<RequestRUShare>{
    return this._http.post<RequestRUShare>(
      this.urlApi + '/api/Portal/VideosCompartidos/Validate?Token='+token,
      {},
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  //end share externo

  //share interno
  ShareVideoInterno(video: VideoShareInterno): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/VideoCompartidoUsuarios/Insert',
      {
        GrabacionCod: video.GrabacionCod,
        Usuario: video.Usuario,
        UsuarioFinal: video.UsuarioFinal
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  UpdateStatusShareInterno(share_id: number): Observable<RequestRU>{
    return this._http.put<RequestRU>(
      this.urlApi + '/api/Portal/VideoCompartidoUsuarios/Deactivate?videoCompartidoUsuarioID='+share_id,
      {

      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  getVideosShareInterno(filterVideo: FilterVideoShare): Observable<RequestVideo> {
    return this._http.get<RequestVideo>(
      this.urlApi + '/api/Portal/VideoCompartidoUsuarios/GrabacionesTableView?usePagination=true&pageIndex=' +
      filterVideo.pageIndex+'&pageRecords='+filterVideo.pageRecords+'&orderField='+filterVideo.orderField
      +'&orderDirection='+filterVideo.orderDirection+'&procesoCod='+filterVideo.ProcesoCod+'&usuarioFinal='+
      filterVideo.Usuario,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      })
  }
  //end share interno

  // services hosts
  getHosts(filter: FilterHost): Observable<RequestHost> {
    let active = ''
    if(filter.Activo)
      active = '1'
    else
      active = '0'
    return this._http.get<RequestHost>(
      this.urlApi + '/api/Portal/ServidorVideos/TableView?usePagination=true&Activo='+active+
      '&pageIndex='+filter.pageIndex+'&pageRecords='+filter.pageRecords+'&orderField=FechaRegistro'+
      '&orderDirection=ASC&Nombre='+filter.Nombre+'&IP='+filter.IP,

      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
      )
  }
  RegisterHost(host: Host){
    return this._http.post(
      this.urlApi + '/api/Portal/ServidorVideos/Insert',
      {
        "IP": host.IP,
        "Usuario": host.Usuario,
        "Clave": host.Clave,
        "Ruta":  host.Ruta,
        "Puerto": host.Puerto,
        "Nombre": host.Nombre,
        "Activo": 1
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  UpdateHost(host: Host){
    return this._http.put(
      this.urlApi + '/api/Portal/ServidorVideos/Update',
      {
        "ServidorVideoCod" : host.ServidorVideoCod,
        "IP" : host.IP,
        "Usuario" : host.Usuario,
        "Clave" : host.Clave,
        "Ruta" :  host.Ruta,
        "Puerto" : host.Puerto,
        "Nombre" : host.Nombre
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  updateStatus(host: Host){
    let active: boolean
    if(host.Activo)
      active = false;
    else
      active = true
    return this._http.put(
      this.urlApi+'/api/Portal/ServidorVideos/StateUpdate',
      {
        ServidorVideoCod: host.ServidorVideoCod,
        Activo: active,
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  // end services host


  // services tags
  RegisterTag(tag: Label): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/Etiquetas/Insert',
      {
        "GrabacionCod": tag.GrabacionCod,
        "Nombre": tag.Nombre
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  RemoveTag(tag: Label): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/Etiquetas/Delete',
      tag,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  // end services tags

  // services attachment
  RegisterAttachment(attachment: any, vide_id: string){
    const formData = new FormData();
    formData.append('', attachment.get('fileSource').value);
    return this._http.post(
      this.urlApi + '/api/Portal/Adjuntos/UploadFile?GrabacionCod='+vide_id,
      formData,
      {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  RemoveAttachment(attach_id: string){
    return this._http.post(
      this.urlApi + '/api/Portal/Adjuntos/Delete',
      {
        "AdjuntoCod": attach_id
      },
      {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )

  }
  // end services attachment

  // services tags
  RegisterTagVideo(tag: TiempoEtiqueta): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/TiempoEtiquetas/Insert',
      {
        "GrabacionCod": tag.GrabacionCod,
        "Segundos": tag.Segundos,
        "Descripcion": tag.Descripcion
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  RemoveTagVideo(tag: TiempoEtiqueta): Observable<RequestRU>{
    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/TiempoEtiquetas/Delete',
      {
        TiempoEtiquetaCod: tag.TiempoEtiquetaCod
      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  // end services tags

  DeactivateVideo(id: any){
    return this._http.put(
      this.urlApi + '/api/Portal/Grabaciones/Deactivate?GrabacionCod='+id,
      {

      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  UpdateReservedVideo(id: any){
    return this._http.put(
      this.urlApi + '/api/Portal/Grabaciones/UpdateReserved?GrabacionCod='+id,
      {

      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  RequestTranscription(GrabacionCod: string): Observable<RequestRU>{
    let email = localStorage.getItem("user")
    if(email === '' || email===null)
      email = ''

    return this._http.post<RequestRU>(
      this.urlApi + '/api/Portal/Transliterador/CreateWav?GrabacionCod='+GrabacionCod+'&email='+email,
      {

      },
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      },
    )
  }
  // http://localhost:53798/api/Portal/Transliterador/CreateWav?GrabacionCod=236&email=carlos.quinonez@outlook.com

  // service filter trasliterable
  // /api/Portal/Transliterador/TableView?usePagination=true&pageIndex= 1&pageRecords= 20&orderField=FechaCreacion&orderDirection=DESC&juzgadoCodList=ALL&texto=

  getVideosTransliterator (filterVideo: any): Observable<RequestVideo> {
    localStorage.setItem('formTransliterator', filterVideo.textTrasliteration)
    return this._http.get<RequestVideo>(
      this.urlApi + '/api/Portal/Transliterador/TableView?usePagination=true&pageIndex=' +
      filterVideo.pageIndex+'&pageRecords='+filterVideo.pageRecords+'&orderField='+
      filterVideo.orderField+'&orderDirection='+filterVideo.orderDirection+
      "&juzgadoCodList="+filterVideo.juzgadoCodList+"&texto="+filterVideo.textTrasliteration,
      {
        headers: new HttpHeaders().set('Authorization', "Basic "+ this.basic)
      }
    )
  }
  // end service filter trasliterable


  //functions dashboard
  getStatisticDateCompany(user: string): Observable<DateCompany[]> {
    return this._http.get<DateCompany[]>(this.urlApi + '/api/Portal/Stats/ValidityDateByCompanyOrder?UserId=' + user,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }


  getStatisticTimeConsumed(user: string): Observable<TimeConsumed[]> {
    return this._http.get<TimeConsumed[]>(this.urlApi + '/api/Portal/Stats/ReportTimeConsumedCards?UserId=' + user,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }


  getStatisticDailyConsumption(user: string): Observable<DailyConsumption[]> {
    return this._http.get<DailyConsumption[]>(this.urlApi + '/api/Portal/Stats/DailyConsumptionDetail?UserId=' + user,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }


  getStatisticAccumulatedConsumption(user: string, time:string): Observable<AccumulatedConsumption[]> {
    return this._http.get<AccumulatedConsumption[]>(this.urlApi + '/api/Portal/Stats/AccumulatedMonthlyConsumptionByCompany?UserId=' + user + '&TimeFilter=' + time,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }


  getStatisticMonthlyConsumption(user: string, time:string): Observable<MonthlyConsumption[]> {
    return this._http.get<MonthlyConsumption[]>(this.urlApi + '/api/Portal/Stats/MonthlyConsumptionByCompany?UserId=' + user + '&TimeFilter=' + time,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }


  getStatisticConsumption(user: string, time:string): Observable<Consumption[]> {
    return this._http.get<Consumption[]>(this.urlApi + '/api/Portal/Stats/ConsumptionByCompany?UserId=' + user,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }

  getStatisticValidationUser(user: string): Observable<UserValidation[]> {
    return this._http.get<UserValidation[]>(this.urlApi + '/api/Portal/Stats/ValidateDashboardUser?UserId=' + user,
    {
      headers: new HttpHeaders().set('Authorization', "Basic " + this.basic)
    });
  }

}
