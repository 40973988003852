<app-toolbar [token]="token" [breadcrumbs]="breadcrumbs" [ProcedureValue]="ProcedureValue"></app-toolbar>
<div class="main-content">
  <div class="row">
    <div class="col xl8 l8 m12 s12">
      <video
        class="video-js vjs-big-play-centered pgg-video vjs-16-9"
        controls
        playsinline
        preload="none" #idVideo>
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
    <div class="col xl4 l4 m12 s12">
      <form [formGroup]="attachmentForm" (submit)="UploadAttachment()">
        <div class="row" *ngIf="show_progres">
          <div class="col s10">
            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
          </div>
          <div class="col s2">
            {{progress}}%
          </div>

        </div>

        <div class="row">
          <div class="col s12 m6 offset-m3 l12 xl12">
            <mat-label>Adjunto</mat-label>
            <input type="file"
                   formControlName="file"
                   (change)="onFileChange($event)">
          </div>
        </div>

        <div class="row">
          <div class="col col s12 m6 offset-m4 l12 xl12">
            <button mat-button mat-flat-button
                    [disabled]="attachmentForm.invalid"
                    class="aling-checkbox-button background-color-buttom">
              <mat-icon>save</mat-icon> Adjuntar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <mat-tab-group>
        <mat-tab label="Lista de adjuntos">
          <div class="row" *ngIf="show_progres_delete">
            <div class="col s10">
              <mat-progress-bar mode="determinate" [value]="progress_delete"></mat-progress-bar>
            </div>
            <div class="col s2">
              {{progress_delete}}%
            </div>
          </div>
          <table mat-table [dataSource]="video.Adjuntos" class="centered" *ngIf="video.Adjuntos.length > 0">
            <ng-container matColumnDef="Nombre">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let attachment"> {{attachment.NombreArchivo}} </td>
            </ng-container>
            <ng-container matColumnDef="gestion">
              <th mat-header-cell *matHeaderCellDef> Gestión </th>
              <td mat-cell *matCellDef="let attachment">
                <a mat-button matTooltip="Descargar adjunto"
                   href="{{urlApi}}/api/Portal/Adjuntos/DownloadFile?adjuntoCod={{attachment.AdjuntoCod}}"
                   target="_blank"
                   mat-flat-button >
                  <mat-icon>get_app</mat-icon>
                </a>
                <a mat-button matTooltip="Eliminar adjunto"
                   mat-flat-button color="warn" (click)="DeleteAttachment(attachment.AdjuntoCod)">
                  <mat-icon>close</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
          </table>
          <p *ngIf="video.Adjuntos.length == 0">
            No hay archivos adjuntos
          </p>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>
</div>


<app-footer></app-footer>
