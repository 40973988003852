import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from '@angular/forms';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


import { environment } from '../../../environments/environment'
import {Video, FilterVideo, RequestVideo, Download, RequestRU} from '../../models/video'
import { Pagination } from '../../models/host'
import { User, RequestToken } from '../../models/user'
import { OdooService } from '../../services/odoo.service'
import { ApiService } from '../../services/api.service'
import { LogsService } from '../../services/logs.service'

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class VideoComponent implements OnInit {
  showProbresBar: boolean = true
  keyFilter: string = 'Nombre'
  module = "videos"
  breadcrumbs = []
  pageSizes = environment.pageSizes;
  filterAdvanced: boolean = false
  token = ''
  user_id = ''
  color = '#FFFFFF'
  filterVideo: FilterVideo = {
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
    general: '',
    juzgadoCodList: 'ALL',
    useDateFilter: '1',
    lowerDate: '',
    upperDate: '',
    intervinienteNombre: '',
    procesoCod: '',
    etiqueta: '',
    district: '-1',
    area: '-1'
  }
  videos: Video[] = []
  user:RequestToken
  pagination: Pagination = {
    CurrentPage: 0,
    TotalPages: 0,
    StartRow: 0,
    FinalRow: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }

  ProcedureValue = {
    "id": 0,
    "name": "",
    "type": "secretary",
    "judged": "",
    "judged_id": "",
    "office": "",
    "entity_name": "",
    "specialty_name": "",
    "email": "",
    "name_complete": "",
    "extra_permission": ""
  }
  requestVideo: RequestVideo

  optionsArea: string[] = []
  optionsDistricts : any[] = [];
  optionsJudged: any[] = []
  strJudgedList: string

  disabledDistricts: boolean = true
  disabledJudged: boolean = true

  @ViewChild('areaInput') areaInput;
  @ViewChild('districtInput') districtInput;
  @ViewChild('judgedInput') judgedInput;
  @ViewChild('judgedInput2') judgedInput2;

  constructor(private activeRoute: ActivatedRoute,
              public odooService: OdooService,
              public apiService: ApiService,
              public logService: LogsService,
              private router: Router) {}

  ngOnInit(): void {
    localStorage.removeItem('formTransliterator')
    this.activeRoute.params.subscribe(params => {
      let token_storage = localStorage.getItem('token')
      this.token = params.token;
      this.user_id = params.user_id;
      if(this.token != token_storage){
        this.odooService.getUser(this.token, this.user_id).subscribe(
          requestUser => {
            let requestSave = <RequestRU> requestUser
            if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
              this.ProcedureValue = requestSave.ProcedureValue;
              localStorage.setItem('token', params.token)
              localStorage.setItem('user_id', params.user_id)
              localStorage.setItem('user', this.ProcedureValue.email)

              localStorage.setItem('data_user', JSON.stringify(this.ProcedureValue))
              this.logService.createLog(this.module,
                "Ingreso al aplicativo con token válido: "+this.token).subscribe(
                request =>console.log("ok"),
                error => console.error(error)
              )
              this.breadcrumbs = [
                {name: 'Inicio', url: '/'+this.token+'/'+params.user_id},
              ]
              if(this.ProcedureValue.type != 'scheduler'){
                if(this.ProcedureValue.extra_permission != "")
                {
                  this.apiService.getJuzgedOtherUser().subscribe(
                    request =>{
                      this.optionsJudged = request;
                      if(this.optionsJudged.length > 0)
                      {
                        let arrayJudged: any[] = []
                        let count_judged = 0
                        for (let judged of this.optionsJudged) {
                          if (arrayJudged.length > 20)
                            break
                          arrayJudged.push(judged.JuzgadoCod)
                        }
                        this.strJudgedList = arrayJudged.join()
                        localStorage.setItem("strJudgedList", this.strJudgedList)
                        this.filterVideo.juzgadoCodList = this.strJudgedList

                        this.disabledJudged = false
                        this.getVideo(false)
                      }
                    },
                    error => {

                    }
                  )
                }else {
                  this.filterVideo.juzgadoCodList = this.ProcedureValue.judged_id
                  this.getVideo(false)
                }
              }
              else{
                this.getVideo(false)
              }
            }
            else
              this.exit()
          },
          error => {
                console.log(error);
                localStorage.clear();
                this.exit();
              })
      }
      else if(token_storage != null){
        this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
        let lsform = localStorage.getItem('formSearch')
        if(lsform !== null){
          let formSearch = JSON.parse(lsform)
          if(this.ProcedureValue.extra_permission !== "")
          {
            this.strJudgedList = localStorage.getItem("strJudgedList")
            this.filterVideo.juzgadoCodList = this.strJudgedList
            this.apiService.getJuzgedOtherUser().subscribe(
              request =>{
                this.optionsJudged = request;
                if(this.optionsJudged.length > 0)
                {
                  this.filterVideo.pageRecords = formSearch.pageRecords
                  this.filterVideo.pageIndex = formSearch.pageIndex
                  if (formSearch.useDateFilter === '1')
                  {
                    this.filterVideo.upperDate = new Date(formSearch.upperDate)
                    this.filterVideo.lowerDate = new Date(formSearch.lowerDate)
                  }else{
                    this.filterVideo.upperDate = ''
                    this.filterVideo.lowerDate = ''
                  }
                  this.filterVideo.etiqueta = formSearch.etiqueta
                  this.filterVideo.intervinienteNombre = formSearch.intervinienteNombre
                  this.filterVideo.procesoCod = formSearch.procesoCod
                  if(formSearch.useDateFilter === '1' || formSearch.intervinienteNombre !== '' || formSearch.etiqueta)
                    this.filterAdvanced = true
                  this.getVideo(true)
                }
              },
              error => {

              }
            )
          }else{
            this.filterVideo.pageRecords = formSearch.pageRecords
            this.filterVideo.juzgadoCodList = formSearch.juzgadoCodList
            this.filterVideo.pageIndex = formSearch.pageIndex
            if (formSearch.useDateFilter === '1')
            {
              this.filterVideo.upperDate = new Date(formSearch.upperDate)
              this.filterVideo.lowerDate = new Date(formSearch.lowerDate)
            }else{
              this.filterVideo.upperDate = ''
              this.filterVideo.lowerDate = ''
            }
            this.filterVideo.etiqueta = formSearch.etiqueta
            this.filterVideo.intervinienteNombre = formSearch.intervinienteNombre
            this.filterVideo.procesoCod = formSearch.procesoCod
            if(formSearch.useDateFilter === '1' || formSearch.intervinienteNombre !== '' || formSearch.etiqueta)
              this.filterAdvanced = true
            this.getVideo(true)
          }

        }
        else{
          if(this.ProcedureValue.type != 'scheduler'){
            if(this.ProcedureValue.extra_permission !== "")
            {
              this.apiService.getJuzgedOtherUser().subscribe(
                request =>{
                  this.optionsJudged = request;
                  if(this.optionsJudged.length > 0)
                  {
                    this.strJudgedList = localStorage.getItem("strJudgedList")
                    this.filterVideo.juzgadoCodList = this.strJudgedList
                    this.getVideo(false)
                  }
                },
                error => {

                }
              )
            }else{
              this.filterVideo.juzgadoCodList = this.ProcedureValue.judged_id
              this.getVideo(false)
            }
          }
        }
        this.breadcrumbs = [
          {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        ]

      }
      else
        this.exit()

    })

    this._getFilterArea()

    // this.filteredOptionsDistricts = this.apiService.getDistricts();

  }

  exit(): void{
    this.router.navigate(['/'])
  }

  pageOnChange(event: PageEvent){
    this.showProbresBar = true;
    if(this.filterVideo.pageRecords != event.pageSize){
      this.filterVideo.pageIndex = '1';
      this.filterVideo.pageRecords = event.pageSize;
    }
    else{
      this.filterVideo.pageIndex = String(event.pageIndex + 1);
    }

    this.getVideo(false)
  }

  showFilterAdvanced(): void{
    this.filterAdvanced = !this.filterAdvanced;
  }

  getVideo(init: boolean): void{
    this.showProbresBar = true
    this.apiService.getVideos(this.filterVideo, init).subscribe(
      requestVideos => {
        this.requestVideo = requestVideos;
        this.videos = this.requestVideo.Data;
        this.pagination = this.requestVideo.Pagination[0]
        this.showProbresBar = false
      },
      err => {
        console.log(err)
        this.showProbresBar = false
      }
    )
  }

  cleanFilter():void{
    this.filterVideo = {
      usePagination: true,
      orderField: 'FechaGrabacion',
      orderDirection: 'DESC',
      pageRecords: this.filterVideo.pageRecords,
      pageIndex: '1',
      general: '',
      juzgadoCodList: 'ALL',
      useDateFilter: '0',
      lowerDate: '',
      upperDate: '',
      intervinienteNombre: '',
      procesoCod: '',
      etiqueta: '',
      district: '-1',
      area: '-1'
    }
    if(this.ProcedureValue.type === 'secretary' && this.ProcedureValue.extra_permission !== ''){
      this.filterVideo.juzgadoCodList = this.strJudgedList
      this.judgedInput2.clear()
    }

    else if(this.ProcedureValue.type != 'scheduler' && this.ProcedureValue.extra_permission === '')
      this.filterVideo.juzgadoCodList = this.ProcedureValue.judged_id
    this.optionsDistricts = []
    if(this.ProcedureValue.type === 'scheduler'){
      this.optionsJudged = []
      this.areaInput.clear()
      this.districtInput.clear()
      this.judgedInput.clear()
      this.disabledDistricts = true
      this.disabledJudged = true
    }
    this.getVideo(false)
  }

  private _getFilterArea(): void{
    this.apiService.getArea().subscribe(
      request => {
        this.optionsArea = request;
      },
      error => {
        console.log(error);
      }
    )
  }

  onChangeAreaEvent(val: string){
    this.filterVideo.area = '-1'
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.disabledDistricts = true
    this.disabledJudged = true
    this.districtInput.clear()
    this.judgedInput.clear()
    this.areaInput.clear()
    this.optionsDistricts = []
    this.optionsJudged = []
  }
  onFocusedAreaEvent(e){
    this.filterVideo.area = '-1'
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.disabledDistricts = true
    this.disabledJudged = true
    this.districtInput.clear()
    this.judgedInput.clear()
    this.optionsDistricts = []
    this.optionsJudged = []
  }
  selectAreaEvent(item){
    this.filterVideo.area = item.AreaCod
    this.filterVideo.district = '-1'
    this.districtInput.clear()
    this.judgedInput.clear()
    this.filterVideo.juzgadoCodList = 'ALL'
    this.optionsJudged = []
    this.apiService.getDistricts(this.filterVideo.area).subscribe(
      request =>{
        this.optionsDistricts = request;
        if(this.optionsArea.length > 0)
        {
          this.disabledDistricts = false
          this.disabledJudged = true
        }
        else
        {
          this.disabledDistricts = true
          this.disabledJudged = true
        }
      },
      error => {

      }
    )
    this.getVideo(false)
  }


  selectDistrictsEvent(item){
    this.filterVideo.district = item.DistritoCod;
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.apiService.getJuzged(this.filterVideo.district, this.filterVideo.area).subscribe(
      request =>{
        this.optionsJudged = request;
        if(this.optionsJudged.length > 0)
        {
          this.disabledJudged = false
        }
      },
      error => {

      }
    )
    this.getVideo(false)
  }

  onChangeDistrictsEvent(val: string){
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.disabledJudged = true
  }
  onFocusedDistrictsEvent(e){
    this.filterVideo.district = '-1'
    this.filterVideo.juzgadoCodList = 'ALL'
    this.judgedInput.clear()
    this.optionsJudged = []
    this.disabledJudged = true
    this.getVideo(false)
  }


  selectJudgedEvent(item){
    this.filterVideo.juzgadoCodList = item.JuzgadoCod
    this.getVideo(false)
  }

  onFocusedJudgedEvent(e){
    if(this.ProcedureValue.type === 'secretary' && this.ProcedureValue.extra_permission !== ''){
      this.filterVideo.juzgadoCodList = this.strJudgedList
      this.judgedInput2.clear()
    }
    else {
      this.filterVideo.juzgadoCodList = 'ALL'
      this.getVideo(false)
    }
  }
  onChangeJudgedEvent(val: string){
    this.filterVideo.juzgadoCodList = this.strJudgedList
    this.judgedInput2.clear()
  }


}
