<mat-card><!--src="https://vjs.zencdn.net/v/oceans.png"-->
  <mat-card-header>
    <mat-card-title> Proceso: {{video.ProcesoCod}}</mat-card-title>
    <mat-card-subtitle>Fecha: {{video.FechaGrabacion | date:"yyyy/MM/dd"}}</mat-card-subtitle>
  </mat-card-header>
  <a routerLink='/detail/{{ video.GrabacionCod }}' matTooltip="Reproducir video">
    <!--<img mat-card-image
         src="https://oneproduccions.com/wp-content/uploads/2015/05/video-streaming-icon.jpg"
         alt="Photo of a Shiba Inu" >-->
    <video
      class="video-js vjs-big-play-centered pgg-video vjs-16-9"
      playsinline
      preload=metadata #idVideo>
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p>
    </video>
  </a>

  <mat-card-content>
    <p>
      Tipo de audiencia: <ng-container *ngIf="video.TipoAudiencia == 'L' "><strong>Libre</strong></ng-container>
      <ng-container *ngIf="video.TipoAudiencia == 'R'"><strong>Reservada</strong></ng-container>
    </p>
    <p>
      Organo: <strong>{{video.EntidadCodAudiencia}}</strong>
    </p>
  </mat-card-content>
  <mat-card-actions>
    <a mat-button color="primary"
       matTooltip="Descargar"
       href="{{urlApi}}/api/filevideo/descargarArchivo?videoName={{video.NombreArchivo}}" download target="_blank">
      <mat-icon>get_app</mat-icon>
    </a>
    <a mat-button color="primary"
       matTooltip="Gestionar palabras claves"
       routerLink="/tags/{{ video.GrabacionCod }}" *ngIf="ProcedureValue.type === 'scheduler'">
      <mat-icon>label</mat-icon>
    </a>
    <a mat-button color="primary"
       matTooltip="Gestionar ajuntos"
       routerLink="/attachments/{{ video.GrabacionCod }}" *ngIf="ProcedureValue.type === 'scheduler'">
      <mat-icon>attach_file</mat-icon>
    </a>
  </mat-card-actions>
</mat-card>
