import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Host} from "../../../models/host";
import {Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { RequestRU } from "../../../models/video"
import {LogsService} from "../../../services/logs.service";

@Component({
  selector: 'app-createhosts',
  templateUrl: './createhosts.component.html',
  styleUrls: ['./createhosts.component.scss']
})
export class CreatehostsComponent implements OnInit {
  password = 'password'
  module = "equipos"
  message: string = ''
  breadcrumbs = []
  token: string
  id: string
  host: Host = {
    Clave: '',
    IP: '',
    Nombre: '',
    Puerto: '21',
    Ruta: '',
    Usuario: '',
    ServidorVideoCod: 0,
    Activo: true
  }
  ProcedureValue = {}
  constructor(private router: Router,
              public apiService: ApiService,
              private _snackBar: MatSnackBar,
              public logService: LogsService,
              private formBuilder: FormBuilder) { }

  form: FormGroup;
  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    // validar datos de usuario
    if(this.token != null)
    {
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      this.form = this.formBuilder.group({
        Clave: ['', {validators: [Validators.required]}],
        IP: ['', {validators: [Validators.required]}],
        Nombre: ['', {validators: [Validators.required]}],
        Puerto: ['21', {validators: [Validators.required]}],
        Ruta: ['', {validators: [Validators.required]}],
        Usuario: ['', {validators: [Validators.required]}]
      })
      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Equipos', url: '/hosts/'},
        {name: 'Crear Equipo', url: '/hosts/create/'},
      ]
    }
    else
      this.exit()
  }
  exit(): void{
    this.router.navigate(['/'])
  }

  back(): void{
    this.router.navigate(['/hosts/'])
  }
  clearForm(): void{
    this.host = {
      Clave: '',
      IP: '',
      Nombre: '',
      Puerto: '21',
      Ruta: '',
      Usuario: '',
      ServidorVideoCod: 0,
      Activo: true
    }
  }

  saveHost(): void{
    this.apiService.RegisterHost(this.host).subscribe(
      request =>{
        let requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          localStorage.setItem('msghost', "Registro exitoso del equipo");
          var str_host = 'IP: '+this.host.IP+', Puerto: '+this.host.Puerto+', Usuario: '+this.host.Usuario+', Ruta: '+
            this.host.Ruta+', Nombre: '+this.host.Nombre;
          this.logService.createLog(this.module,
            "Creación de equipo: "+ str_host).subscribe(
            request =>console.log("ok"),
            error => console.error(error)
          )
          this.back();
        }
      },
      error => {
        console.log(error)
        console.log('Error')
        this._snackBar.open("Error al registrar el Equipo", null,
          {
            duration: 6000,
            panelClass: "red-snackbar"
          })
      }
    )
  }

  changeType(): void{
    if(this.password == 'password'){
      this.password = 'text'
    }
    else{
      this.password = 'password'
    }
  }
}
