import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from "ngx-cookie-service"

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagVideoComponent } from './components/tag-video/tag-video.component';
import { AppComponent } from './components/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { CustomMatPaginatorIntl } from './paginator-es'
/* Components App */
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LogsComponent } from './components/logs/logs.component';
import { VideoComponent } from './components/video/video.component';
import { DetailvideoComponent } from './components/detailvideo/detailvideo.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ListhostsComponent } from './components/hosts/listhosts/listhosts.component';
import { CreatehostsComponent } from './components/hosts/createhosts/createhosts.component';
import { UpdatehostsComponent } from './components/hosts/updatehosts/updatehosts.component';
import { ShareComponent } from './components/share/share.component';
import { DetailshareComponent } from './components/detailshare/detailshare.component';
import { ListshareComponent } from './components/listshare/listshare.component';
import { SvideoshareComponent } from './components/svideoshare/svideoshare.component';
import { TagsComponent } from './components/tags/tags.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PublicComponent } from './components/public/public.component';
import { PublicdetailComponent } from './components/publicdetail/publicdetail.component';
import { SvideopublicComponent } from './components/svideopublic/svideopublic.component';
import { SvideoComponent } from './components/svideo/svideo.component';
/* Components App */

/* modules material */
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { TransliteratedComponent } from './components/transliterated/transliterated.component';
import { SprocessComponent } from './components/sprocess/sprocess.component';
import { SProcessVideoComponent } from './components/sprocessvideo/sprocessvideo.component';
import { SProcessDetailComponent } from './components/sprocessdetail/sprocessDetail.component';
import { CardVideoComponent } from './components/card-video/card-video.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { NgApexchartsModule } from "ng-apexcharts";


/* End modules material*/

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    LogsComponent,
    VideoComponent,
    DetailvideoComponent,
    LogoutComponent,
    SvideoComponent,
    ListhostsComponent,
    CreatehostsComponent,
    UpdatehostsComponent,
    ShareComponent,
    DetailshareComponent,
    ListshareComponent,
    SvideoshareComponent,
    TagsComponent,
    AttachmentsComponent,
    PublicComponent,
    PublicdetailComponent,
    SvideopublicComponent,
    TagVideoComponent,
    ConfirmDialogComponent,
    FooterComponent,
    TransliteratedComponent,
    SprocessComponent,
    SProcessVideoComponent,
    SProcessDetailComponent,
    CardVideoComponent,
    StatisticsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // Material
    MatButtonModule, MatCardModule,
    MatToolbarModule, MatTableModule,
    MatIconModule, MatInputModule,
    MatTooltipModule, MatSelectModule,
    MatFormFieldModule, MatMenuModule,
    MatSidenavModule, MatPaginatorModule,
    MatGridListModule, MatTabsModule,
    MatProgressBarModule, MatChipsModule,
    MatExpansionModule, MatDatepickerModule,
    MatNativeDateModule, MatRippleModule,
    MatCheckboxModule, MatSnackBarModule,
    MatProgressSpinnerModule, MatDialogModule,
    MatAutocompleteModule, MatDividerModule,
    // Routing
    AppRoutingModule, AutocompleteLibModule,
    NgApexchartsModule,
  ],
  entryComponents: [ConfirmDialogComponent],
  // providers services
  providers: [
    CookieService,
    { provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }

  ],

  //
  bootstrap: [AppComponent]
})
export class AppModule { }
