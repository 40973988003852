import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import { Host, FilterHost, RequestHost, Pagination } from "../../../models/host"
import {ApiService} from "../../../services/api.service"
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RequestRU } from "../../../models/video"
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import {LogsService} from "../../../services/logs.service";


@Component({
  selector: 'app-listhosts',
  templateUrl: './listhosts.component.html',
  styleUrls: ['./listhosts.component.scss']
})
export class ListhostsComponent implements OnInit {
  module = "equipos"
  showProgresBar = false
  displayedColumns: string[] = [
    "IP",
    "Usuario",
    "Clave",
    "Ruta",
    "Nombre",
    "Puerto",
    "options"
  ]
  pageSizes = environment.pageSizes;
  breadcrumbs = []
  token: string = ''
  filterHosts: FilterHost = {
    usePagination: 'true',
    pageIndex: '1',
    pageRecords: '10',
    orderField: '',
    orderDirection: '',
    Activo: true,
    Nombre: '',
    IP: ''
  }
  hosts: any
  pagination: Pagination = {
    CurrentPage: 0,
    FinalRow: 0,
    StartRow: 0,
    TotalPages: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }
  requestHosts: RequestHost
  ProcedureValue = {}
  constructor(private router: Router,
              public apiService: ApiService,
              public logService: LogsService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    localStorage.removeItem("host")
    let msghost = localStorage.getItem('msghost')
    this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
    if(msghost != null){
      this.showMsg(msghost);
      localStorage.removeItem("msghost")
    }
    // validar datos de usuario
    if(this.token != null)
    {
      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Equipos', url: '/hosts/'},
      ]
      this.startGetHosts();
    }
    else
      this.exit()
  }

  exit(): void{
    this.router.navigate(['/'])
  }

  edit(host: Host): void{
    let url = "/hosts/edit/"+host.ServidorVideoCod
    localStorage.setItem('host', JSON.stringify(host))
    this.router.navigate([url])
  }
  newHost(): void{
    this.router.navigate(["/hosts/create/"])
  }
  startGetHosts(){
    this.showProgresBar = true
    this.apiService.getHosts(this.filterHosts).subscribe(
      requestHosts => {
        this.requestHosts = <RequestHost>requestHosts;
        this.hosts = this.requestHosts.Data;
        this.pagination = this.requestHosts.Pagination[0]
        this.showProgresBar = false
      },
      err => console.log(err)
    )
  }
  pageOnChange(event: PageEvent){
    if(this.filterHosts.pageRecords != String(event.pageSize)) {
      this.filterHosts.pageIndex = '1';
      this.filterHosts.pageRecords = String(event.pageSize);
    }
    else
      this.filterHosts.pageIndex = String(event.pageIndex + 1);
    this.startGetHosts()
  }
  clearFilter(): void{
    this.filterHosts.IP = '';
    this.filterHosts.Nombre = '';
  }

  showMsg(msg: string):void{
    this._snackBar.open(msg, null,
      {
        duration: 8000,
        panelClass: 'green-snackbar',
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
      })
  }

  updateStatus(host: Host): void{
    this.showProgresBar = true
    this.apiService.updateStatus(host).subscribe(
      request =>{
        this.logService.createLog(this.module,
          'Actualización de estado del host: '+ host.IP + 'Estado: '+host.Activo)
        let requestSave = <RequestRU> request;
        if(requestSave.ExecutionStatus && requestSave.ProcedureStatus){
          this.showMsg("Estado modificado correctamente");
          this.startGetHosts();
        }
        this.showProgresBar = false

      },
      error =>{

      }
    )
  }
}
