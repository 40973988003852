import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api.service";
import {LogsService} from "../../services/logs.service";
import {Router} from "@angular/router";
import {Pagination} from "../../models/host";
import {Video, FilterVideoShare, RequestVideo} from "../../models/video";
import {environment} from "../../../environments/environment";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-listshare',
  templateUrl: './listshare.component.html',
  styleUrls: ['./listshare.component.scss']
})
export class ListshareComponent implements OnInit {
  module = 'compartidos usuarios'
  token = ''
  showProbresBar: boolean = true
  breadcrumbs = []
  pageSizes = environment.pageSizes;
  pagination: Pagination = {
    CurrentPage: 0,
    FinalRow: 0,
    StartRow: 0,
    TotalPages: 0,
    TotalRows: 0,
    TotalRowsAll: 0
  }
  videos: Video[] = []
  filterVideo: FilterVideoShare = {
    Usuario: '',
    ProcesoCod: '',
    usePagination: true,
    orderField: 'FechaGrabacion',
    orderDirection: 'DESC',
    pageRecords: 10,
    pageIndex: '1',
  }
  requestVideo: RequestVideo
  ProcedureValue = {
    "id": 0,
    "name": "",
    "type": "secretary",
    "judged": "",
    "judged_id": "",
    "office": "",
    "entity_name": "",
    "specialty_name": "",
    "email": "adm04sinc@cendoj.ramajudicial.gov.co",
    "name_complete": ""
  }

  constructor(private router: Router,
              public apiService: ApiService,
              public logService: LogsService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    if(this.token != null)
    {
      this.ProcedureValue = JSON.parse(localStorage.getItem('data_user'))
      this.filterVideo.Usuario = this.ProcedureValue.email
      this.showProbresBar = false;
      this.breadcrumbs = [
        {name: 'Inicio', url: '/'+this.token+'/'+localStorage.getItem('user_id')},
        {name: 'Compartidos', url: '/list_share/'},
      ]
      this.getVideoShare();
    }
    else
      this.exit()
  }

  getVideoShare():void{
    this.showProbresBar = true
    this.apiService.getVideosShareInterno(this.filterVideo).subscribe(
      request => {
        this.requestVideo = request;
        this.videos = this.requestVideo.Data;
        this.pagination = this.requestVideo.Pagination[0]
        this.showProbresBar = false
      },
      err => {
        console.log(err)
        this.showProbresBar = false
      }
    )
  }

  exit(): void{
    this.router.navigate(['/'])
  }
  cleanFilter(): void{
    this.filterVideo = {
      Usuario: '',
      ProcesoCod: '',
      usePagination: true,
      orderField: 'FechaGrabacion',
      orderDirection: 'DESC',
      pageRecords: 10,
      pageIndex: '1',
    }
  }
  pageOnChange(event: PageEvent){
    this.showProbresBar = true;

    if(this.filterVideo.pageRecords != event.pageSize) {
      this.filterVideo.pageIndex = '1';
      this.filterVideo.pageRecords = event.pageSize;
    }
    else
      this.filterVideo.pageIndex = String(event.pageIndex + 1);
    this.getVideoShare()
  }

}
